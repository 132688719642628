import React from 'react';

import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import { useStyles } from './RegisterFailedDialogstyles';
import { ColorSAP, FONT_FAMILY, Typography } from '@commsult/ontego-ui';
import { useTranslation } from 'react-i18next';
import Button from '../../ReusableComponents/Button/Button';

const RegisterFailedDialog = props => {
    const { open, onClose } = props
    const classes = useStyles(props)

    const { t } = useTranslation();

    const handleClose = () => {
        onClose()
    }

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            textTitle={t("register.failedDialog.title")}
            content={
                <div style={{ position: "relative", padding: "0px 24px 40px 24px", display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 588 }}> 
                    <div style={{   display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 510, marginTop: 64, marginBottom: 64 }}>
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 32C1.5 15.1553 15.1553 1.5 32 1.5C48.8447 1.5 62.5 15.1553 62.5 32C62.5 48.8447 48.8447 62.5 32 62.5C15.1553 62.5 1.5 48.8447 1.5 32Z" stroke="#EE4137" stroke-width="3"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M20.8217 20.8215C21.4725 20.1707 22.5278 20.1707 23.1787 20.8215L32.0002 29.643L40.8217 20.8215C41.4725 20.1707 42.5278 20.1707 43.1787 20.8215C43.8295 21.4724 43.8295 22.5277 43.1787 23.1786L34.3572 32L43.1787 40.8215C43.8295 41.4724 43.8295 42.5277 43.1787 43.1786C42.5278 43.8294 41.4725 43.8294 40.8217 43.1786L32.0002 34.3571L23.1787 43.1786C22.5278 43.8294 21.4725 43.8294 20.8217 43.1786C20.1708 42.5277 20.1708 41.4724 20.8217 40.8215L29.6431 32L20.8217 23.1786C20.1708 22.5277 20.1708 21.4724 20.8217 20.8215Z" fill="#EE4137"/>
                        </svg>

                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                            size={18}
                            color={ColorSAP.neutral[100]}
                            style={{ lineHeight: "25px", marginTop: 24, textAlign: "center" }}
                        >
                            {t("register.failedDialog.message")}
                        </Typography>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "end", width: "100%" }}>
                        <Button
                            id="loginButton"
                            type="primary"
                            theme="dark"
                            size="medium"
                            onClick={handleClose}
                        >
                            {t("register.failedDialog.retry")}
                        </Button>
                    </div>
                </div>
            }
        />
    )
};

export default RegisterFailedDialog;