import React from 'react';

import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';

import './video.css';
import { Close, ColorSAP } from '@commsult/ontego-ui';
import { useMediaQuery, useTheme } from '@material-ui/core';

const VideoDialog = props => {
    const { open, onClose } = props

    const handleClose = () => {
        onClose()
    }

    const theme = useTheme();
    const isMobileTabletDisplay = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            textTitle={null}
            noBorderRadius
            noClose
            content={
                <div
                    style={{
                        background: ColorSAP.neutral[100],
                        width: '-webkit-fill-available',
                        height: 'auto',
                        display: 'flex',
                        overflow: 'hidden',
                    }}
                >
                    <div onClick={handleClose} style={{ position: "absolute", right: isMobileTabletDisplay ? 12 : 24, top: isMobileTabletDisplay ? 12 : 24, cursor: 'pointer', height: isMobileTabletDisplay ? 32 : 72, width: isMobileTabletDisplay ? 32 : 72, zIndex: 1 }}>
                        <Close style={{ color: ColorSAP.neutral[0], width: isMobileTabletDisplay ? 32 : 72, height:  isMobileTabletDisplay ? 32 :72, }} />
                    </div>
                    <video
                        id="cockpit-video"
                        autoPlay playsInline controls loop
                    >
                        <source src="assets/videos/cockpit.webm" type="video/webm" />
                    </video>
                </div>
            }
        />
    )
};

export default VideoDialog;