// React Lib
import React from "react";

// Component Styles
import { useStyles } from "./LanguageSwitcherStyles";

import { Typography, FONT_FAMILY, ColorSAP, Color } from "@commsult/ontego-ui";


export const LanguageSwitcher = props => {
    const { i18n, theme, onChangeLanguageClick } = props
    const classes = useStyles(props)

    const isDarkTheme = theme == "dark";
    const defaultTextColor = isDarkTheme ? ColorSAP.neutral[100] : ColorSAP.neutral[0];
    const activeTextColor = isDarkTheme ? ColorSAP.neutral[0] : ColorSAP.primary[70];
    const activeBackgroundColor = isDarkTheme ? ColorSAP.primary[70] : ColorSAP.neutral[0];
    const defaultBackgroundColor = isDarkTheme ? "#FFFFFF" : ColorSAP.primary[70];

    return (
        <div
            className={classes.container}
            style={{
                width: isDarkTheme ? 70 : 57,
                height: isDarkTheme ? 29 : 22,
                border: isDarkTheme ? `1px solid ${ColorSAP.primary[70]}` : `1px solid ${ColorSAP.neutral[0]}`,
                borderRadius: isDarkTheme ? `6px` : "4px",
            }}
        >
            <div
                style={{ width: '50%', height: '100%', borderTopRightRadius: 2, borderBottomRightRadius: 2, background: i18n.language === 'de' ? activeBackgroundColor : defaultBackgroundColor, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', transition: '0.3s all ease-in-out' }}
                onClick={() => onChangeLanguageClick('de')}
            >
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    size={14}
                    color={i18n.language === 'de' ? activeTextColor : defaultTextColor}
                    className={classes.languageDe}
                    style={{
                        fontStyle: i18n.language === 'de' && 'italic'
                    }}
                >
                    {`DE`}
                </Typography>
            </div>
            <div
                style={{ width: '50%', height: '100%', borderTopLeftRadius: 2, borderBottomLeftRadius: 2, background: i18n.language === 'en' ? activeBackgroundColor : defaultBackgroundColor, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', transition: '0.3s all ease-in-out' }}
                onClick={() => onChangeLanguageClick('en')}
            >
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                    size={14}
                    color={i18n.language === 'en' ? activeTextColor : defaultTextColor}
                    className={classes.languageDe}
                    style={{
                        fontStyle: i18n.language === 'en' && 'italic'
                    }}
                >
                    {`EN`}
                </Typography>
            </div>
        </div >
    )
}