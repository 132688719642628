import React, { useState, useContext, useCallback, useEffect } from 'react'
import { Typography, FONT_FAMILY, Color, ColorSAP, ArrowHeadDown, ChevronDown } from '@commsult/ontego-ui'
import { Link, useHistory } from "react-router-dom";

import { AppContext } from '../../App'
import { useStyles } from './RegisterStyles';

import Grid from '@material-ui/core/Grid';

import LoginImage from "../../assets/img/login/login.jpg";

import { SCENARIO_PATH, ADMIN_DASHBOARD, LOGIN_PATH } from '../../Constants/Path';
import { timeout } from '../../Utils/Utils';
import { ROLE_ADMIN } from '../../Constants/ClientRoles';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import Button from '../../ReusableComponents/Button/Button';
import Form from '../../ReusableComponents/Form/Form';
import { LogoDark } from '../../assets/img/logo/logoDark';
import Play from '../../Icons/Play';
import VideoDialog from '../../Dialogs/VideoDialog/VideoDialog';
import { Hidden, OutlinedInput, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { LanguageSwitcher } from '../../ReusableComponents/LanguageSwitcher/LanguageSwitcher';
import RegisterSuccessDialog from '../../Dialogs/RegisterSuccessDialog/RegisterSuccessDialog';
import RegisterFailedDialog from '../../Dialogs/RegisterFailedDialog/RegisterFailedDialog';
import Select from '../../ReusableComponents/Select/Select';
import { getPhonePrefix } from '../../Constants/PhonePrefix';
import { Autocomplete } from '@material-ui/lab';

const Register = props => {
    const classes = useStyles(props);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phonePrefix, setPhonePrefix] = useState({
        value: "+49",
        label: "+49"
    });
    const [phonenumber, setPhonenumber] = useState("");

    const [firstnameError, setFirstnameError] = useState(false);
    const [lastnameError, setLastnameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phonenumberError, setPhonenumberError] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
    const [isRegisterSuccessDialogOpen, setIsRegisterSuccessDialogOpen] = useState(false);
    const [isRegisterFailedSuccessDialogOpen, setIsRegisterFailedDialogOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    
    const theme = useTheme();
    const isMobileDisplay = useMediaQuery(theme.breakpoints.down('xs'));
    const isMobileTabletDisplay = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        setIsFunctionLoading
    } = useContext(AppContext);

    const onChangeLanguageClick = (lang) => {
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", lang)
    };

    const handleChangeFirstname = e => {
        setFirstname(e.currentTarget.value)
        setFirstnameError(false)
    };

    const handleChangeLastname = e => {
        setLastname(e.currentTarget.value)
        setLastnameError(false)
    };

    const handleChangeEmail = e => {
        setEmail(e.currentTarget.value)
        setEmailError(false)
        setEmailInvalid(false)
    };

    const handleChangePhonePrefix = (_, value) => {
        setPhonePrefix(value)
        setErrorMessage("")
    };

    const handleChangePhonenumber = e => {
        setPhonenumber(e.currentTarget.value)
        setPhonenumberError(false)
        setErrorMessage("")
    };

    const handleUserKeyPress = useCallback(event => {
        const { keyCode } = event;

        if (keyCode === 13) {
            if (document.getElementById('registerButton')) document.getElementById('registerButton').click()
        }
    }, []);

    const handlePlayVideo = () => {
        setIsVideoDialogOpen(true)
    }

    const handleCloseVideo = () => {
        setIsVideoDialogOpen(false)
    }

    const handleCloseRegisterDialog = () => {
        setIsRegisterSuccessDialogOpen(false)
        setIsRegisterFailedDialogOpen(false)
    }

    const reset = () => {
        setFirstname("")
        setFirstnameError(false)
        setLastname("")
        setLastnameError(false)
        setEmail("")
        setEmailError(false)
        setEmailInvalid(false)
        setPhonePrefix({
            value: "+49",
            label: "+49"
        })
        setPhonenumber("")
        setPhonenumberError(false)
    }

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const register = async e => {
        e.preventDefault();

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (firstname === "" || lastname === "" || !emailRegex.test(email) || email === "" || phonePrefix === null || phonenumber === "") {
            setFirstnameError(!firstname)
            setLastnameError(!lastname)
            setEmailError(!email)
            setEmailInvalid(!emailRegex.test(email))
            setPhonenumberError(phonePrefix === null || !phonenumber)

            if (phonePrefix === null || !phonenumber) {
                setErrorMessage(t("error.phonenumber"))
            }

            return;
        }
        
        const data = {
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: email,
                },
                {
                    objectTypeId: "0-1",
                    name: "firstname",
                    value: firstname,
                },
                {
                    objectTypeId: "0-1",
                    name: "lastname",
                    value: lastname,
                },
                {
                    objectTypeId: "0-1",
                    name: "phone",
                    value: phonePrefix.value + phonenumber,
                },
            ],
        };

        let formId = currentLanguage == "de" ? process.env.REACT_APP_HUBSPOT_FORM_ID_DE : process.env.REACT_APP_HUBSPOT_FORM_ID_EN
        try {
            setIsFunctionLoading(true)
            const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${formId}`;
    
            await fetch(url, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }).then((response) => {
                onFormSubmitted(response.ok, response);
            });
        } catch (error) {
            onFormSubmitted(false);
        }
        
        await timeout(2000)
    }

    const onFormSubmitted = (isSuccess, response) => {
        reset();
        setIsFunctionLoading(false);
        setIsRegisterSuccessDialogOpen(isSuccess);
        setIsRegisterFailedDialogOpen(!isSuccess);

        if (!isSuccess) {
            console.error("There was an error! :", response);
        }
    };
    
    const registerInfoDesc = t("register.info.desc").split("\n");

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                    <Grid container direction="row-reverse" style={{ height: isMobileTabletDisplay ? "auto" : "100%" }}>
                        <Hidden xsDown>
                            <Grid item xs={12} sm={12} md={6}>
                                <div className={classes.videoContainer} style={{ backgroundImage: `url(${LoginImage})` }}>
                                    <div
                                        className={classes.playButton}
                                        onClick={handlePlayVideo}
                                    >
                                        <Play style={{ width: 80, height: 80, color: ColorSAP.neutral[0] }} />
                                    </div>
                                    <Hidden xsDown>
                                        <div style={{ position: "absolute", top: isMobileTabletDisplay ? 40 : 64, right: isMobileTabletDisplay ? 48 : 120 }}>
                                            <LanguageSwitcher
                                                i18n={i18n}
                                                onChangeLanguageClick={onChangeLanguageClick}
                                                theme="dark"
                                            />
                                        </div>
                                    </Hidden>
                                </div>
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid item sm={12}>
                                <div style={{ margin: "40px 24px 24px 24px" }}>
                                    <LanguageSwitcher
                                        i18n={i18n}
                                        onChangeLanguageClick={onChangeLanguageClick}
                                        theme="dark"
                                    />
                                </div>
                            </Grid>
                        </Hidden>
                        <Grid item sm={12} md={6}>
                            <Grid container style={{ height: "100%" }}>
                                <Grid item xs={12}>
                                    <div id="registerContainer" className={classes.registerContainer}>
                                        <Grid container>
                                            <Grid item xs={12} style={{ marginBottom: isMobileDisplay ? 4 : 24 }}>
                                                <Hidden smDown>
                                                    <LogoDark
                                                        style={{
                                                            width: 158,
                                                            height: 64,
                                                            marginBottom: 56
                                                        }}
                                                    />
                                                </Hidden>
                                                <Typography
                                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                                    size={isMobileDisplay ? 20 : 24}
                                                    color={ColorSAP.neutral[100]}
                                                    className={classes.lineHeight34}
                                                >
                                                    {t("register.info.title")}
                                                </Typography>
                                                {registerInfoDesc.map((line, index) => (
                                                    <Typography
                                                        key={index}
                                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                        size={14}
                                                        color={ColorSAP.neutral[100]}
                                                        className={classes.lineHeight22 + " " + classes.marginBottom22}
                                                    >
                                                        {line}                              
                                                    </Typography>
                                                ))}
                                            </Grid>

                                            <Grid item xs={12} style={{ marginBottom: 40 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6}>
                                                                <div tabIndex={1}>
                                                                    <Form
                                                                        placeholder={`${t('register.form.firstname')}`}
                                                                        label={`${t('register.form.firstname')} *`}
                                                                        onChange={handleChangeFirstname}
                                                                        value={firstname}
                                                                        error={firstnameError}
                                                                        errorMessage={t("error.firstname")}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <div tabIndex={2}>
                                                                    <Form
                                                                        placeholder={`${t('register.form.lastname')}`}
                                                                        label={`${t('register.form.lastname')} *`}
                                                                        onChange={handleChangeLastname}
                                                                        value={lastname}
                                                                        error={lastnameError}
                                                                        errorMessage={t("error.lastname")}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={6} md={12}>
                                                                <div tabIndex={3}>
                                                                    <Form
                                                                        placeholder={`${t('register.form.email')}`}
                                                                        label={`${t('register.form.email')} *`}
                                                                        onChange={handleChangeEmail}
                                                                        value={email}
                                                                        error={emailError || emailInvalid}
                                                                        errorMessage={emailError ? t("error.email") : t("error.emailInvalid")}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                            
                                                            <Grid item xs={12} sm={6} md={12}>
                                                                <Typography
                                                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                                                    size={14}
                                                                    color={phonenumberError ? ColorSAP.danger[60] : ColorSAP.neutral[100]}
                                                                    style={{ lineHeight: "17px" }}
                                                                >
                                                                    {`${t('register.form.phonenumber')} *`}
                                                                </Typography>
                                                                <div tabIndex={4} style={{ display: "flex", alignItems: "flex-end" }}>
                                                                    <div style={{ maxWidth: isMobileTabletDisplay ? 99 : 110, marginRight: 16 }}>
                                                                        <Autocomplete
                                                                            options={getPhonePrefix().map(item => ({
                                                                                value: item,
                                                                                label: item
                                                                            }))}
                                                                            getOptionLabel={(option) => option.label}
                                                                            onChange={handleChangePhonePrefix}
                                                                            value={phonePrefix}
                                                                            renderInput={(params) => 
                                                                                <div style={{ position: "relative", display: "flex", alignItems: "center" }} ref={params.InputProps.ref}>
                                                                                    <OutlinedInput
                                                                                        {...params}
                                                                                        
                                                                                        placeholder={`${t('register.form.phonenumber')} *`}
                                                                                        variant="outlined"
                                                                                        classes={{
                                                                                            root: classes.root,
                                                                                            input: classes.input,
                                                                                            notchedOutline: classes.notchedOutline,
                                                                                            focused: classes.focused,
                                                                                            adornedStart: classes.adornedStart
                                                                                        }}
                                                                                        error={phonenumberError}
                                                                                    />
                                                                                    <div className={classes.dropdownArrowIcon} onMouseDown={params.InputProps.onMouseDown}>
                                                                                        <ChevronDown />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <Form
                                                                        placeholder={`${t('register.form.phonenumber')}`}
                                                                        onChange={handleChangePhonenumber}
                                                                        value={phonenumber}
                                                                        error={phonenumberError}
                                                                    />
                                                                </div>
                                                                {errorMessage && (
                                                                    <Grid item xs={12}>
                                                                        <Typography
                                                                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                                            size={12}
                                                                            color={ColorSAP.danger[50]}
                                                                            style={{ marginTop: 4 }}
                                                                        >
                                                                            {errorMessage}
                                                                        </Typography>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div tabIndex={3}>
                                                    <Button
                                                        id="registerButton"
                                                        type="primary"
                                                        theme="dark"
                                                        size={isMobileTabletDisplay ? "small" : "medium"}
                                                        onClick={register}
                                                    >
                                                        {t("register.register")}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Hidden smDown>
                                    <Grid item xs={12} style={{ display: "flex", alignItems: "end" }}>
                                        <div className={classes.footerContainer}>
                                            <Typography
                                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_LIGHT}
                                                size={14}
                                                color={ColorSAP.neutral[100]}
                                                className={classes.lineHeight22}
                                            >
                                                {t("register.alreadyHaveAcc")}
                                            </Typography>
                                            <Link
                                                to={LOGIN_PATH} style={{ textDecoration: "none" }}
                                            >
                                                <Typography
                                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                                    size={14}
                                                    color={ColorSAP.primary[70]}
                                                    className={classes.lineHeight22}
                                                    style={{ marginLeft: 10, cursor: "pointer" }}
                                                >
                                                {t("register.signIn")}
                                                </Typography>
                                            </Link>
                                        </div>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={12} style={{ display: "flex", alignItems: "end" }}>
                        <div className={classes.footerContainer}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_LIGHT}
                                size={14}
                                color={ColorSAP.neutral[100]}
                                className={classes.lineHeight22}
                            >
                                {t("register.alreadyHaveAcc")}
                            </Typography>
                            <Link
                                to={LOGIN_PATH} style={{ textDecoration: "none" }}
                            >
                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                    size={14}
                                    color={ColorSAP.primary[70]}
                                    className={classes.lineHeight22}
                                    style={{ marginLeft: 10, cursor: "pointer" }}
                                >
                                {t("register.signIn")}
                                </Typography>
                            </Link>
                        </div>
                    </Grid>
                </Hidden>
            </Grid>
            <VideoDialog
                open={isVideoDialogOpen}
                onClose={handleCloseVideo}
            />
            <RegisterSuccessDialog
                open={isRegisterSuccessDialogOpen}
                onClose={handleCloseRegisterDialog}
            />
            <RegisterFailedDialog
                open={isRegisterFailedSuccessDialogOpen}
                onClose={handleCloseRegisterDialog}
            />
        </div>
    )
}

export default Register;