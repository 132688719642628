import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export const RobotArm = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" version="1.1">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 4C14.5 3.44772 14.0523 3 13.5 3C12.9477 3 12.5 3.44772 12.5 4V7.46351V8H8.32929C7.91746 6.83481 6.80622 6 5.5 6C3.84315 6 2.5 7.34315 2.5 9C2.5 10.3113 3.34135 11.4262 4.5137 11.8341C4.50469 11.8881 4.5 11.9435 4.5 12V19H2.5C1.94772 19 1.5 19.4477 1.5 20C1.5 20.5523 1.94772 21 2.5 21H5.5H8.5C9.05228 21 9.5 20.5523 9.5 20C9.5 19.4477 9.05228 19 8.5 19H6.5V12C6.5 11.9435 6.49531 11.8881 6.4863 11.8341C7.34517 11.5352 8.0264 10.857 8.32929 10H12.5V10.5376V14C12.5 14.5523 12.9477 15 13.5 15C14.0523 15 14.5 14.5523 14.5 14V12.6804L15.0119 13.2289C15.5309 13.7849 16.3203 13.9985 17.0488 13.7799L21.7873 12.3584C22.3163 12.1997 22.6165 11.6422 22.4578 11.1132C22.2991 10.5842 21.7416 10.284 21.2127 10.4427L16.4741 11.8643L14.5 9.74923V9V8.25184L16.4741 6.13678L21.2127 7.55836C21.7416 7.71706 22.2991 7.41688 22.4578 6.88788C22.6165 6.35889 22.3163 5.80141 21.7873 5.64271L17.0488 4.22113C16.3203 4.00259 15.5309 4.21614 15.0119 4.77215L14.5 5.32066V4ZM6.5 9C6.5 8.44772 6.05228 8 5.5 8C4.94772 8 4.5 8.44772 4.5 9C4.5 9.55228 4.94772 10 5.5 10C6.05228 10 6.5 9.55228 6.5 9Z" />
        </SvgIcon>
    );
}

export default RobotArm;