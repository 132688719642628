// Material-UI Styles
import { ColorSAP, FONT_FAMILY } from '@commsult/ontego-ui';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
    createStyles({
        rootContainer: {
            flexGrow: 1,
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            position: "relative",
            marginTop: -80
        },
        videoContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: 'center',
            height: "100%",
            width: "100%",
            [theme.breakpoints.only('sm')]: {
                height: "320px",
            },
        },
        playButton: {
            padding: 24,
            width: 56,
            height: 56,
            borderRadius: 240,
            backgroundColor: ColorSAP.primary[70],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            [theme.breakpoints.up('md')]: {
                width: 80,
                height: 80,
            }
        },
        registerContainer: {
            flexGrow: 1,
            boxSizing: "border-box",
            maxHeight: "100%",
            margin: "0px 24px 24px 24px",
            [theme.breakpoints.up('sm')]: {
                margin: "48px 48px 0px 48px",
            },
            [theme.breakpoints.up('md')]: {
                margin: "64px 90px 64px 120px",
            },
        },
        footerContainer: {
            display: "flex", 
            margin: "0px 24px 64px 24px",
            marginTop: 40,
            [theme.breakpoints.up('sm')]: {
                marginTop: 47,
                margin: "0px 48px 48px 48px"
            },
            [theme.breakpoints.up('md')]: {
                marginTop: 5,
                margin: "0px 90px 64px 120px"
            },
        },
        lineHeight34: {
            letterSpacing: "unset",
            lineHeight: "28px",
            marginBottom: 16,
            [theme.breakpoints.up('sm')]: {
                lineHeight: "32px",
                marginBottom: 24
            },
        },
        lineHeight22: {
            letterSpacing: "unset",
            lineHeight: "20px",
            [theme.breakpoints.up('sm')]: {
                lineHeight: "22px",
            },
        },
        marginBottom22: {
            marginBottom: 20,
            [theme.breakpoints.up('sm')]: {
                marginBottom: 23
            },
        },


        root: {
            width: '100%',
            background: ColorSAP.neutral[10],
            paddingRight: "40px",
            "&:hover $notchedOutline": {
                borderColor: ColorSAP.neutral[40]
            },
            "&$focused $notchedOutline": {
                borderWidth: "1px !important",
                borderColor: ColorSAP.neutral[100]
            }
        },
        input: {
            border: 'none',
            background: ColorSAP.neutral[10],
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
            fontSize: 14,
            color: ColorSAP.neutral[100],
            '&::placeholder': {
                fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
                fontSize: 14,
                color: `${ColorSAP.neutral[40]} !important`,
                opacity: 1
            }
        },
        notchedOutline: {
            // borderWidth: "1px !important",
            // borderColor: `${ColorSAP.neutral[100]} !important`,
            borderColor: ColorSAP.neutral[40]
        },
        focused: {
            border: 'none'
        },
        adornedStart: {
            "& svg": {
                width: 24,
                height: 24,
                color: ColorSAP.neutral[40],
                marginRight: 12
            }
        },
        dropdownArrowIcon: {
            position: "absolute",
            right: 16,
            color: ColorSAP.neutral[90],
            width: 24,
            height: 24
        }
    })
)