// React Lib
import React, { useContext, useState } from "react";

// Component Styles
import { ColorSAP, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import ProjectPlanningImgDE from '../../assets/img/scenario/project-planning-de.png';
import ProjectPlanningImg from '../../assets/img/scenario/project-planning.png';
import FormSubmissionFailedDialog from "../../Dialogs/FormSubmissionFailedDialog/FormSubmissionFailedDialog";
import FormSubmissionSuccessDialog from "../../Dialogs/FormSubmissionSuccessDialog/FormSubmissionSuccessDialog";
import Button from "../../ReusableComponents/Button/Button";
import AuthService from "../Login/AuthService";

export const OntegoChecklists = () => {
    const { t, i18n } = useTranslation()
    const [isFormSubmissionSuccessDialogOpen, setIsFormSubmissionSuccessDialogOpen] = useState(false);
    const [isFormSubmissionFailedDialogOpen, setIsFormSubmissionFailedDialogOpen] = useState(false);
    const currentLanguage = i18n.language;
    const { client } = useContext(AppContext);

    const submitRequest = async () => {
        try {
            const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
            const formENId = process.env.REACT_APP_SUBMISSION_FORM_ID_EN;
            const formDEId = process.env.REACT_APP_SUBMISSION_FORM_ID_DE;
            const formId = currentLanguage === 'en' ? formENId : formDEId;
            const baseUrl = 'https://api.hsforms.com/submissions/v3/integration/submit'
            const nameParts = client.name.trim().split(/\s+/);
            const email = client.email;
            const firstname = nameParts[0] || "";
            const lastname = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
            const response = await axios.post(`${baseUrl}/${portalId}/${formId}`, {
                fields: [
                    {
                        objectTypeId: "0-1",
                        name: "email",
                        value: email,
                    },
                    {
                        objectTypeId: "0-1",
                        name: "firstname",
                        value: firstname,
                    },
                    {
                        objectTypeId: "0-1",
                        name: "lastname",
                        value: lastname,
                    }
                ]
            });
            return response;
        } catch(error) {
            console.error("There was an error", error);
            throw error;
        }
    }

    const handleDownloadPDF = () => {
        const info = AuthService.getUserInfo();
        submitRequest().then(response => {
            if(response.request.status !== 200) return
            setIsFormSubmissionSuccessDialogOpen(true);
        })
        .catch(error => {
            setIsFormSubmissionFailedDialogOpen(true);
        })
    }

    const handleCloseRegisterDialog = () => {
        setIsFormSubmissionSuccessDialogOpen(false);
        setIsFormSubmissionFailedDialogOpen(false);
    }

    return (
        <>
            <div
                id={`scenario-ontego-checklists`}
                style={{
                    position: 'relative',
                    height: 146,
                    padding: '24px 32px 24px 32px',
                    borderRadius: 12,
                    overflow: 'hidden',
                    background: `linear-gradient(270deg, ${ColorSAP.primary[70]} 68.08%, ${ColorSAP.primary[90]} 112.75%)`
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: 284,
                        height: 154,
                        right: 48,
                        backgroundImage: `url(${currentLanguage === 'en' ? ProjectPlanningImg : ProjectPlanningImgDE})`,
                        backgroundSize: 'cover',
                        zIndex: 1
                    }}
                />
                <div
                    style={{
                        width: 837,
                        height: 498,
                        background: 'radial-gradient(60% 60% at 65.07% 50%, rgba(234, 230, 59, 0.7) 0%, rgba(234, 230, 59, 0) 100%)',
                        position: 'absolute',
                        top: -70,
                        right: 0
                    }}
                />
                <div
                    style={{
                        position: 'relative',
                        maxWidth: 791
                    }}
                >
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={24}
                        color={ColorSAP.neutral[0]}
                        style={{
                            marginBottom: 8
                        }}
                    >
                        {t("scenario.ontegoChecklists")}
                    </Typography>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[0]}
                        style={{
                            marginBottom: 20
                        }}
                    >
                        {t("scenario.ontegoChecklistsDesc")}
                    </Typography>

                    <Button
                        type="primary"
                        theme="light"
                        size="small"
                        onClick={handleDownloadPDF}
                    >
                        {t("scenario.ontegoChecklistsDownload")}
                    </Button>
                </div>
            </div>
            <FormSubmissionSuccessDialog
                open={isFormSubmissionSuccessDialogOpen}
                onClose={handleCloseRegisterDialog}
            />
            <FormSubmissionFailedDialog
                open={isFormSubmissionFailedDialogOpen}
                onClose={handleCloseRegisterDialog}
            />
        </>
    )
}

export default React.memo(OntegoChecklists)