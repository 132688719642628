// Material-UI Styles

// Constants
import { ColorSAP } from "@commsult/ontego-ui";
import { createStyles, makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        upAndRunningContainer: {
            position: 'relative',
            boxSizing: 'border-box',
            borderRadius: 8,
            padding: 16,
            backgroundColor: ColorSAP.neutral[0],
            width: 365
        },
        upAndRunningContainerIsDraggingOver: {
            position: 'relative',
            boxSizing: 'border-box',
            borderRadius: 8,
            padding: 16,
            backgroundColor: ColorSAP.neutral[15],
            width: 330,
            height: 'fit-content',
        },
        dragAndDropPlaceholderText: {
            width: '100%',
            paddingTop: 35,
            paddingBottom: 25,
            textAlign: 'center'
        },
        timelineInputContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 32
        },
        functionBoxCollectionNoteActionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            "& svg": {
                width: 24,
                height: 24
            }
        },
        functionCollectionNoteActionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    })
);