// React Lib
import React, { useEffect, useState } from "react";

// Component Styles
import { useStyles } from "./DrawerStyles";

import { ColorSAP, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import { useTranslation } from "react-i18next";
import { F_SONDERFUNKTIONEN_ERWEITERUNGEN } from "../../Constants/FunctionIds";
import Tooltip from "../Tooltip/Tooltip";

export const Drawer = (props) => {
    const classes = useStyles(props)
    const [activeMenu, setActiveMenu] = useState({})
    const [firstLoad, setFirstLoad] = useState(true)

    const { t } = useTranslation()

    const { id, activeOption, setActiveOption, isProcessTypeAlreadyAddedToCollection, collection, menus,
        isScrolling, setIsScrolling, setAppPreviewNavIndex, style, toolTipTitle, inspectedFunctionId, setActiveData } = props

    // useEffect(() => {
    //     // window.scrollTo(0, 0)
    //     // console.log(window.location.hash.slice(5))
    //     menus.forEach((menu, index) => {
    //         if (menu.name === window.location.hash.slice(5)) {
    //             console.log(menu)
    //             handleMenuOnClick(menu)()
    //         }
    //     })
    // }, [])

    // useScrollToAnchor(menus, setActiveMenu, setAppPreviewNavIndex, setIsScrolling, setActiveOption, setActiveData);

    useEffect(() => {
        if (activeOption !== `` && !isScrolling && !firstLoad) {
            menus.forEach((menu, index) => {
                if (menu.name === activeOption) {
                    setActiveMenu(menu)
                    setAppPreviewNavIndex(0)
                }
            })
        }
        // setTimeout(() => {
        //     let element = document.getElementById(lastMenu.name)
        //     // let elementHeader = document.getElementById('breadcrumb-container').offsetHeight

        //     window.scrollTo(0, element.offsetTop)

        // }, 500);
        // else {
        //     setActiveMenu(menus[0])
        //     setAppPreviewNavIndex(0)
        // }
        setFirstLoad(false)
    }, [activeOption])

    // to anchor scroll
    useEffect(() => {
        if (menus) {
            let lastMenu = null;
            const hash = window.location.hash;

            var hashName = hash.substring(6);

            if (hashName !== '') {
                menus.forEach((eachElement, index) => {
                    if (eachElement.name === hashName) {
                        lastMenu = eachElement
                    }
                });
                if (lastMenu && lastMenu.name) {
                    setTimeout(() => {
                        let element = document.getElementById(lastMenu.name)
                        // let elementHeader = document.getElementById('breadcrumb-container').offsetHeight

                        // window.scrollTo(0, element.offsetTop)
                        element.scrollIntoView()
                    }, 500);
                }
            }
        }
    }, [menus])

    const handleMenuOnClick = (menu) => () => {
        setIsScrolling(true)
        setActiveMenu(menu)
        setAppPreviewNavIndex(0)


        let selectedIndex = 0;
        menus.forEach((eachElement, index) => {
            if (eachElement.name === menu.name)
                selectedIndex = index
        });
        const sectionHash = `#menu${menu.name}`;
        window.location.hash = sectionHash;
        let lastMenu = null;
        menus.forEach((eachElement, index) => {
            if (parseInt(index) === parseInt(selectedIndex))
                lastMenu = eachElement
        });
        setTimeout(() => {
            let element = document.getElementById(lastMenu.name)
            let elementHeader = document.getElementById('breadcrumb-container').offsetHeight
            element.scrollIntoView()
        }, 100);
    }

    return (
        <>
            <div
                style={{
                    // paddingLeft: 8,
                    width: "100%",
                    ...style
                }}
                id={id}
            >
                {menus.map((menu, index) => (
                    <div
                        id={`menu${index}`}
                        key={`menu${index}`}
                        className={classes.drawerMenu}
                    >
                        {menu.name !== "" && (
                            <>
                                <a
                                    style={{
                                        textDecoration: "none"
                                    }}
                                // href={`#${menu.name}`}
                                >
                                    <div
                                        className={classes.drawerMenuLabel}
                                        onClick={handleMenuOnClick(menu)}
                                    >
                                        <Typography
                                            fontFamily={menu.name === activeMenu.name ? FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD_ITALIC : FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                            size={16}
                                            color={menu.name === activeMenu.name ? ColorSAP.primary[70] : ColorSAP.neutral[100]}
                                        >
                                            {inspectedFunctionId === F_SONDERFUNKTIONEN_ERWEITERUNGEN ? menu.name : t(`processFunction.${inspectedFunctionId}.${menu.name}.title`)}
                                        </Typography>
                                    </div>
                                </a>
                                <>
                                    {isProcessTypeAlreadyAddedToCollection(collection, menu) && (
                                        <Tooltip title={toolTipTitle} placement="right">
                                            <div className={classes.drawerMenuIsCustomizedTag} />
                                        </Tooltip>
                                    )}
                                </>
                            </>
                        )}
                    </div>
                ))}
            </div>
            <a id="link-drawer" style={{ visibility: "hidden" }} />
        </>
    )
}

Drawer.defaultProps = {
    menus: [
        {
            name: 'Menu 1'
        },
        {
            name: 'Menu 2'
        },
    ],
    isProcessTypeAlreadyAddedToCollection(collection, menu) {
        return null;
    },
    toolTipTitle: "Added to Collection"
}

export default React.memo(Drawer)