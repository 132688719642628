import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export const Monitor = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" version="1.1">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 3C3.46957 3 2.96086 3.21071 2.58579 3.58579C2.21071 3.96086 2 4.46957 2 5V15C2 15.5304 2.21071 16.0391 2.58579 16.4142C2.96086 16.7893 3.46957 17 4 17H8V19H7C6.44772 19 6 19.4477 6 20C6 20.5523 6.44772 21 7 21H9H15H17C17.5523 21 18 20.5523 18 20C18 19.4477 17.5523 19 17 19H16V17H20C20.5304 17 21.0391 16.7893 21.4142 16.4142C21.7893 16.0391 22 15.5304 22 15V5C22 4.46957 21.7893 3.96086 21.4142 3.58579C21.0391 3.21071 20.5304 3 20 3H4ZM15 15H20V5L4 5V15H9H15ZM14 17V19H10V17H14Z" />
        </SvgIcon>
    );
}

export default Monitor;