// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
import { Color, ColorSAP } from "@commsult/ontego-ui";

export const useStyles = makeStyles((theme) =>
    createStyles({
        navbarContainer: {
            position: "fixed",
            width: "100%",
            top: 0,
            zIndex: 1300,
        },
        navbar: {
            width: '100vw',
            height: 64,
            background: ColorSAP.primary[70],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        navbarChildWrapper: {
            [theme.breakpoints.between('1280', '1440')]: {
                maxWidth: 1200,
            },
            [theme.breakpoints.up('1440')]: {
                maxWidth: 1200,
            },
            display: "flex",
            width: '100%',
            alignItems: "center",
        },

        navbarLogoContainer: {
            marginRight: 100
        },
        navbarLogo: {
            width: `88px !important`,
            height: `44px !important`,
            cursor: "pointer"
        },

        navbarAccountIconContainer: {
            "& svg": {
                width: 30,
                height: 30,
                marginRight: 24,
                color: Color.primary[100],
                cursor: "pointer"
            }
        },

        navbarShoppingCartIconContainer: {
            position: 'relative',
            "& svg": {
                width: 24,
                height: 24,
                marginRight: 24,
                color: Color.neutral[0],
                cursor: "pointer"
            }
        },
        navbarCollectionSizeIndicator: {
            position: 'absolute',
            top: -3,
            right: 20,
            height: 16,
            background: ColorSAP.secondary[30],
            borderRadius: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },

        navbarChildContainer: {
            height: "100%",
            display: "inline-flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "flex-end"
        },

        navbarMenuContainer: {
            width: '100%',
            height: '100%',
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
        },

        navbarAccountImage: {
            width: 32,
            height: 32,
            borderRadius: "50%",
            backgroundColor: Color.primary[100],
            marginRight: 24,
            cursor: "pointer"
        },

        menuNavContainer: {
            width: 'fit-content',
            height: 64,
            userSelect: "none",
            color: Color.neutral[0],
            cursor: "pointer",
            display: "flex",
            alignItems: "center",

            position: 'relative',
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 15,
                left: 0,
                width: 0,
                height: 1,
                backgroundColor: 'white',
                transition: 'width 600ms ease',
            },
            '&:hover::after': {
                width: '100%',
                left: 0,
            },
        },

        hoverNavMenuContainer: {
            display: "flex",
            transition: "0.2s",

            "&:hover $subNavContainer": {
                visibility: "visible",
                opacity: 1,
            },

            "&:hover $arrowDown": {
                // visibility: "hidden",
                transform: "rotate(180deg)",
                transition: "0.15s all linear"
            },
        },

        arrowDown: {
            marginLeft: 4,
            opacity: 1,
            visibility: "visible",
            transition: "0.15s all linear"
        },

        arrowUp: {
            width: 0,
            opacity: 0,
            visibility: "hidden",
            transition: "visibility 0s, opacity 0.3s linear",
        },

        arrowRightHidden: {
            width: 0,
            paddingLeft: 10,
            position: "absolute"
        },

        arrowRight: {
            paddingLeft: 15,
            position: "absolute"
        },

        subNavContainer: {
            display: "flex",
            position: "absolute",
            left: 0,
            width: "100%",
            top: "100%",
            zIndex: 1300,
            background: `linear-gradient(179deg, #000 -375.58%, #3B3FEA 98.98%)`,
            visibility: "hidden",
            opacity: 0,
            transition: "visibility 0s, opacity 0.3s linear",
            paddingTop: 80,
            paddingBottom: 80,
            boxSizing: 'border-box'
        },

        subNavItemContainer: {
            height: 116,
            padding: 16,
            boxSizing: 'border-box',
            // margin: "0 15px",
            display: "flex",
            alignItems: 'center',
            cursor: "pointer",
            transition: "0.2s background-color",
            borderRadius: 12,

            "&:hover": {
                backgroundColor: ColorSAP.primary[80]
            },

            "&:hover $arrowRightHidden": {
                animation: "$fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both",
                width: "auto",
            }
        },

        arrowHover: {
            // "&:hover $arrowRightHidden": {
            //     animation: "$fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both",
            //     width: "auto",
            // }
        },

        subNavItemContainer350: {
            width: 350,
        },

        subNavItemContainer450: {
            width: 450,
        },

        subNavIcon: {
            width: `80px !important`,
            height: `80px !important`,
            flexGrow: 1,
            marginRight: 24,
            color: ColorSAP.primary[30],
            "& svg": {
                color: ColorSAP.primary[30]
            }
        },

        subNavText: {
            width: '100%',
            flexGrow: 2,
            userSelect: "none"
        },

        "@keyframes fade-in-left": {
            "0%": {
                transform: "translateX(-50px)",
                opacity: 0
            },
            "100%": {
                transform: "translateX(0)",
                opacity: 1
            }
        },

        // ressSubNavSeparator: {
        //     paddingTop: 40,
        //     paddingBottom: 40
        // },

        ressSubNavRightContainer: {
            // textAlign: "left",
            marginLeft: 100,
            paddingLeft: 100,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            borderLeft: `1px solid ${Color.neutral[0]}`,
            userSelect: "none",
        },

        ressSubNavLeftContainer: {
            paddingTop: 10,
            paddingBottom: 10,
            // [theme.breakpoints.between('1280', '1440')]: {
            //     paddingLeft: 40,
            // },
            // [theme.breakpoints.up('1440')]: {
            //     paddingLeft: 120,
            // },
        },

        languageDe: {},
        languageEn: {}

    })
);