import ProcessImage from "../../Models/ProcessImage";
import ProcessImageContainer from "../../Models/ProcessImageContainer";

/** PRODUKTIONSVERSORGUNG IMAGES */
import Produktionsversorgung_Einfuehrung_EWM1 from '../../assets/img_en/produktionsversorgung/Einfuehrung_EWM/1.png';
import Produktionsversorgung_Einfuehrung_MM1 from '../../assets/img_en/produktionsversorgung/Einfuehrung_MM/1.png';
import Produktionsversorgung_Einfuehrung_WM1 from '../../assets/img_en/produktionsversorgung/Einfuehrung_WM/1.png';

import Produktionsversorgung_Stueckliste1 from '../../assets/img_en/produktionsversorgung/Stueckliste/1.png';
import Produktionsversorgung_Stueckliste2 from '../../assets/img_en/produktionsversorgung/Stueckliste/2.png';
import Produktionsversorgung_Stueckliste3 from '../../assets/img_en/produktionsversorgung/Stueckliste/3.png';
import Produktionsversorgung_Stueckliste4 from '../../assets/img_en/produktionsversorgung/Stueckliste/4.png';
import Produktionsversorgung_Stueckliste5 from '../../assets/img_en/produktionsversorgung/Stueckliste/5.png';
import Produktionsversorgung_Stueckliste6 from '../../assets/img_en/produktionsversorgung/Stueckliste/6.png';

import Produktionsversorgung_Fertigungsauftrag_WM1 from '../../assets/img_en/produktionsversorgung/Fertigungsauftrag_WM/1.png';
import Produktionsversorgung_Fertigungsauftrag_WM2 from '../../assets/img_en/produktionsversorgung/Fertigungsauftrag_WM/2.png';
import Produktionsversorgung_Fertigungsauftrag_WM3 from '../../assets/img_en/produktionsversorgung/Fertigungsauftrag_WM/3.png';
import Produktionsversorgung_Fertigungsauftrag_WM4 from '../../assets/img_en/produktionsversorgung/Fertigungsauftrag_WM/4.png';
import Produktionsversorgung_Fertigungsauftrag_WM5 from '../../assets/img_en/produktionsversorgung/Fertigungsauftrag_WM/5.png';

import Produktionsversorgung_Fertigungsauftrag_EWM1 from '../../assets/img_en/produktionsversorgung/Fertigungsauftrag_EWM/1.png';
import Produktionsversorgung_Fertigungsauftrag_EWM2 from '../../assets/img_en/produktionsversorgung/Fertigungsauftrag_EWM/2.png';
import Produktionsversorgung_Fertigungsauftrag_EWM3 from '../../assets/img_en/produktionsversorgung/Fertigungsauftrag_EWM/3.png';
import Produktionsversorgung_Fertigungsauftrag_EWM4 from '../../assets/img_en/produktionsversorgung/Fertigungsauftrag_EWM/4.png';
import Produktionsversorgung_Fertigungsauftrag_EWM5 from '../../assets/img_en/produktionsversorgung/Fertigungsauftrag_EWM/5.png';

import Produktionsversorgung_KanbanStatuswechsel1 from '../../assets/img_en/produktionsversorgung/KanbanStatuswechsel/1.png';

import Produktionsversorgung_WA_Fertigungsauftrag_WM1 from '../../assets/img_en/warenausgang_intern/Fertigungsauftrag/1.png';
import Produktionsversorgung_WA_Fertigungsauftrag_WM2 from '../../assets/img_en/warenausgang_intern/Fertigungsauftrag/2.png';
import Produktionsversorgung_WA_Fertigungsauftrag_WM3 from '../../assets/img_en/warenausgang_intern/Fertigungsauftrag/3.png';
import Produktionsversorgung_WA_Fertigungsauftrag_WM4 from '../../assets/img_en/warenausgang_intern/Fertigungsauftrag/4.png';
import Produktionsversorgung_WA_Fertigungsauftrag_WM5 from '../../assets/img_en/warenausgang_intern/Fertigungsauftrag/5.png';

import Produktionsversorgung_WA_Reservierung1 from '../../assets/img_en/warenausgang_intern/Reservierung/1.png';
import Produktionsversorgung_WA_Reservierung2 from '../../assets/img_en/warenausgang_intern/Reservierung/2.png';
import Produktionsversorgung_WA_Reservierung3 from '../../assets/img_en/warenausgang_intern/Reservierung/3.png';
import Produktionsversorgung_WA_Reservierung4 from '../../assets/img_en/warenausgang_intern/Reservierung/4.png';
import Produktionsversorgung_WA_Reservierung5 from '../../assets/img_en/warenausgang_intern/Reservierung/5.png';

export const ProduktionsversorgungImagesEN = [
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_EINFUEHRUNG_MM`,
        [
            new ProcessImage('Details with Serial Numbers', Produktionsversorgung_Einfuehrung_MM1)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_EINFUEHRUNG_WM`,
        [
            new ProcessImage('Material', Produktionsversorgung_Einfuehrung_WM1)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_EINFUEHRUNG_EWM`,
        [
            new ProcessImage('Material', Produktionsversorgung_Einfuehrung_EWM1)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_MATERIALBEREITSTELLUNG_ZUR_STUECKLISTE`,
        [
            new ProcessImage('Material', Produktionsversorgung_Stueckliste1),
            new ProcessImage('Production Order', Produktionsversorgung_Stueckliste2),
            new ProcessImage('Positions', Produktionsversorgung_Stueckliste3),
            new ProcessImage('Details', Produktionsversorgung_Stueckliste4),
            new ProcessImage('Details with Serial Numbers', Produktionsversorgung_Stueckliste5),
            new ProcessImage('Storage Source Location', Produktionsversorgung_Stueckliste6)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_MATERIALBEREITSTELLUNG_ZUM_FERTIGUNGSAUFTRAG_WM`,
        [
            new ProcessImage('Production Order', Produktionsversorgung_Fertigungsauftrag_WM1),
            new ProcessImage('Positions', Produktionsversorgung_Fertigungsauftrag_WM2),
            new ProcessImage('Details', Produktionsversorgung_Fertigungsauftrag_WM3),
            new ProcessImage('Material', Produktionsversorgung_Fertigungsauftrag_WM4),
            new ProcessImage('From the Storage', Produktionsversorgung_Fertigungsauftrag_WM5)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_MATERIALBEREITSTELLUNG_ZUM_FERTIGUNGSAUFTRAG_EWM`,
        [
            new ProcessImage('Production Order', Produktionsversorgung_Fertigungsauftrag_EWM1),
            new ProcessImage('Positions', Produktionsversorgung_Fertigungsauftrag_EWM2),
            new ProcessImage('Details', Produktionsversorgung_Fertigungsauftrag_EWM3),
            new ProcessImage('Material', Produktionsversorgung_Fertigungsauftrag_EWM4),
            new ProcessImage('From the Storage', Produktionsversorgung_Fertigungsauftrag_EWM5)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_MATERIALBEREITSTELLUNG_IN_DER_SERIENFERTIGUNG_WM`,
        [
            new ProcessImage('Status Change', Produktionsversorgung_KanbanStatuswechsel1)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_MATERIALBEREITSTELLUNG_IN_DER_SERIENFERTIGUNG_EWM`,
        [
            new ProcessImage('Status Change', Produktionsversorgung_KanbanStatuswechsel1)
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_WARENAUSGANG_ZUM_FERTIGUNGSAUFTRAG`,
        [
            new ProcessImage('Menu', Produktionsversorgung_WA_Fertigungsauftrag_WM1),
            new ProcessImage('Fertigungsauftrag', Produktionsversorgung_WA_Fertigungsauftrag_WM2),
            new ProcessImage('Material', Produktionsversorgung_WA_Fertigungsauftrag_WM3),
            new ProcessImage('Vonlagerort per Auswahl', Produktionsversorgung_WA_Fertigungsauftrag_WM4),
            new ProcessImage('Menge', Produktionsversorgung_WA_Fertigungsauftrag_WM5),
        ]
    ),
    new ProcessImageContainer(
        `P_PRODUKTIONSVERSORGUNG_WARENAUSGANG_ZUR_RESERVIERUNG`,
        [
            new ProcessImage('Menu', Produktionsversorgung_WA_Reservierung1),
            new ProcessImage('Reservierung', Produktionsversorgung_WA_Reservierung2),
            new ProcessImage('Material', Produktionsversorgung_WA_Reservierung3),
            new ProcessImage('Vonlagerort', Produktionsversorgung_WA_Reservierung4),
            new ProcessImage('Menge', Produktionsversorgung_WA_Reservierung5),
        ]
    )
]