// Material-UI Styles
import { ColorSAP } from '@commsult/ontego-ui';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
    createStyles({
        rootContainer: {
            flexGrow: 1,
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            position: "relative",
            marginTop: -80
        },
        videoContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: 'center',
            height: "100%",
            width: "100%",
            [theme.breakpoints.only('sm')]: {
                height: "320px",
            },
        },
        videoThumbnailContainer: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: -1,
            overflow: 'hidden'
        },
        videoThumbnail: {
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        },
        playButton: {
            padding: 24,
            width: 56,
            height: 56,
            borderRadius: 240,
            backgroundColor: ColorSAP.primary[70],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            [theme.breakpoints.up('md')]: {
                width: 80,
                height: 80,
            }
        },
        loginContainer: {
            boxSizing: "border-box",
            maxHeight: "100%",
            padding: "0px 24px 24px 24px",
            height: "100%",
            [theme.breakpoints.only('sm')]: {
                maxWidth: "539px"
            },
            [theme.breakpoints.up('sm')]: {
                padding: "48px 48px 0px 48px"
            },
            [theme.breakpoints.up('md')]: {
                padding: "64px 90px 64px 120px",
            },
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
        },
        footerContainer: {
            display: "flex", 
            margin: "0px 24px 64px 24px",
            marginTop: 44,
            [theme.breakpoints.up('sm')]: {
                marginTop: 47,
                margin: "0px 48px 48px 48px"
            },
            [theme.breakpoints.up('md')]: {
                marginTop: 65,
                margin: "0px 90px 64px 120px"
            },
        },
        lineHeight34: {
            letterSpacing: "unset",
            lineHeight: "28px",
            marginBottom: 16,
            [theme.breakpoints.up('sm')]: {
                lineHeight: "34px",
                marginBottom: 24
            },
        },
        lineHeight22: {
            letterSpacing: "unset",
            lineHeight: "20px",
            [theme.breakpoints.up('sm')]: {
                lineHeight: "22px",
            },
        },
        marginBottom22: {
            marginBottom: 20,
            [theme.breakpoints.up('sm')]: {
                marginBottom: 24
            },
        },
        title: {
            marginBottom: 16, 
            lineHeight: "42px",
            [theme.breakpoints.up('sm')]: {
                marginBottom: 24, 
                lineHeight: "80px" 
            }
        },
        description: {
            marginBottom: 20, 
            lineHeight: "20px",
            letterSpacing: "unset", 
            [theme.breakpoints.up('sm')]: {
                lineHeight: "22px", 
                marginBottom: 21,
            },
            [theme.breakpoints.up('md')]: {
                lineHeight: "20px", 
                marginRight: 25,
                marginBottom: 24,
            }
        }
    })
)