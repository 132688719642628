// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
import { Color, ColorSAP } from "@commsult/ontego-ui";

export const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            overflow: "hidden"
        },
        switcher_dark: {
            '&:hover': {
                background: `${ColorSAP.primary[20]} !important`
            }
        },
        switcher_light: {
            '&:hover': {
                background: `${ColorSAP.primary[50]} !important`
            }
        }
    })
);