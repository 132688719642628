// React Lib
import React from "react";

// Component Styles
import { useStyles } from "./NavbarStyles";

// @otg-one/ui_components/components
import { Typography } from '@commsult/ontego-ui';

// @otg-one/ui_components/icons
import { AvatarUser, Color, ColorSAP, FONT_FAMILY } from '@commsult/ontego-ui';

import { ArrowDown, Copy, FilesMessage, Headphone, MobileSearch, Settings, TagEuro, Warehouse } from "@commsult/ontego-ui/dist/sap_icons";

import { useTranslation } from 'react-i18next';

// @otg-one/ui_components/constants

import Zoom from '@material-ui/core/Zoom';
import { LogoLight } from "../../assets/img/logo/logoLight";
import Button from "../Button/Button";
import { LanguageSwitcher } from "../LanguageSwitcher/LanguageSwitcher";
import TertiaryButton from "../TertiaryButton/TertiaryButton";


const CollectionCart = (props) => {
    const classes = useStyles(props)
    const { collectionSize, onShoppingCartClick } = props

    return (
        <div className={classes.navbarShoppingCartIconContainer}>
            <Copy onClick={onShoppingCartClick} />
            <Zoom in={collectionSize > 0}>
                <div className={classes.navbarCollectionSizeIndicator} style={{ width: (collectionSize > 0 && collectionSize < 10) ? 16 : 20 }}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_EXTRABOLD}
                        size={12}
                        color={ColorSAP.neutral[100]}
                        style={{ marginTop: 1, marginLeft: 1 }}
                    >
                        {collectionSize}
                    </Typography>
                </div>
            </Zoom>
        </div>
    )
}

const AccountBox = (props) => {
    const classes = useStyles(props)
    const { avatar, onAccountBoxClick } = props

    return (
        <>
            {avatar ? (
                <div
                    className={classes.navbarAccountImage}
                    onClick={onAccountBoxClick}
                >
                    <img
                        src={avatar}
                        alt="avatar"
                        style={{
                            width: 32,
                            height: 32,
                            borderRadius: "50%"
                        }}
                    />
                </div>
            ) : (
                <div className={classes.navbarAccountIconContainer}>
                    <AvatarUser onClick={onAccountBoxClick} />
                </div>
            )}

        </>
    )
}

export const Navbar = (props) => {
    const classes = useStyles(props)
    const { t, i18n } = useTranslation()

    const { userInfo, id, avatar, collectionSize, onLogoClick, onUpAndRunningClick, onShoppingCartClick, onLogoutClick, onChangeLanguageClick, onPricingAndLicenseClick, onProcessesClick, onManagementClick, onPriceModelClick } = props

    const handleOpenLink = url => {
        window.open(url, "_blank")
    }

    const licenseModelsSubNavMenus = [
        {
            icon: <Warehouse className={classes.subNavIcon} />,
            title: t("navbar.solutions"),
            content: t("navbar.solutionsExp"),
            contentWidth: 273,
            url: 'https://ontego.de#solutions'
        },
        {
            icon: <MobileSearch className={classes.subNavIcon} />,
            title: t("navbar.hardwareFinder"),
            content: t("navbar.hardwareFinderExp"),
            contentWidth: 273,
            url: 'https://www.ontego.de/sap_mobility/mobile-geraete.html'
        },
        {
            icon: <FilesMessage className={classes.subNavIcon} />,
            title: t("navbar.mobilityBlog"),
            content: t("navbar.mobilityBlogExp"),
            contentWidth: '100%',
            url: 'https://blog.ontego.de'
        },
        {
            icon: <Headphone className={classes.subNavIcon} />,
            title: t("navbar.supportPortal"),
            content: t("navbar.supportPortalExp"),
            contentWidth: '100%',
            url: 'https://support.commsult.de'
        }
    ];

    const resourcesSubNavMenus = [
        {
            icon: <TagEuro className={classes.subNavIcon} />,
            title: t("navbar.simplyUse"),
            content: t("navbar.simplyUseExp"),
            onClick: onPriceModelClick
        },
        {
            icon: <Settings className={classes.subNavIcon} />,
            title: t("navbar.mobileSolution90"),
            content: t("navbar.mobileSolution90Exp"),
            onClick: onUpAndRunningClick
        },
    ];

    return (
        <div className={classes.navbarContainer}>
            <div className={classes.navbar}>
                <div className={classes.navbarChildWrapper}>
                    <div className={classes.navbarLogoContainer}>
                        <LogoLight
                            className={classes.navbarLogo}
                            onClick={onLogoClick}
                        />
                    </div>
                    <div className={classes.navbarMenuContainer}>
                        <div
                            style={{
                                marginRight: 56
                            }}
                        >
                            <TertiaryButton
                                noIcon
                                size="medium"
                                theme="dark"
                                onClick={onProcessesClick}
                            >
                                {t(`navbar.processes`)}
                            </TertiaryButton>
                        </div>

                        <div className={classes.hoverNavMenuContainer}>
                            <div className={classes.menuNavContainer} style={{ marginRight: 56 }}>
                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                    size={18}
                                    color={Color.neutral[0]}
                                    style={{ lineHeight: "64px", borderBottom: "2px solid transparent" }}
                                >
                                    {t(`navbar.resources`)}
                                </Typography>

                                <ArrowDown className={classes.arrowDown} />
                                {/* <ArrowUp className={classes.arrowUp} /> */}
                            </div>


                            <div className={classes.subNavContainer} style={{ justifyContent: "center", padding: '80px 120px 80px 120px' }}>
                                <div style={{ width: 1200, height: 264, display: 'grid', gridTemplateColumns: '50% 50%' }}>
                                    {licenseModelsSubNavMenus.map((menu, i) =>
                                        <a onClick={() => handleOpenLink(menu.url)} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            <div key={menu.title} className={`${classes.subNavItemContainer}`}>
                                                {menu.icon}
                                                <div className={classes.subNavText}>
                                                    <Typography
                                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                                        size={18}
                                                        color={Color.neutral[100]}
                                                        style={{ marginBottom: 8 }}
                                                    >
                                                        {menu.title}
                                                    </Typography>
                                                    <Typography
                                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                        size={12}
                                                        color={Color.neutral[0]}
                                                        style={{ lineHeight: '17px', width: menu.contentWidth }}
                                                    >
                                                        {menu.content}
                                                        {/* <ArrowRight className={classes.arrowRightHidden} /> */}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>

                        {userInfo && userInfo.role !== 'PROSPECT' && (
                            <div
                                style={{ marginRight: 48 }}
                            >
                                <TertiaryButton
                                    noIcon
                                    size="medium"
                                    theme="dark"
                                    onClick={onManagementClick}
                                >
                                    {t(`navbar.management`)}
                                </TertiaryButton>
                            </div>
                        )}
                    </div>
                    <div className={classes.navbarChildContainer}>
                        <div style={{ marginRight: 24 }}>
                            <LanguageSwitcher
                                i18n={i18n}
                                onChangeLanguageClick={onChangeLanguageClick}
                            />
                        </div>
                        <CollectionCart collectionSize={collectionSize} onShoppingCartClick={onShoppingCartClick} />

                        <Button
                            id={`${id}logoutButton`}
                            type="secondary"
                            size="small"
                            theme="dark"
                            onClick={onLogoutClick}
                        >
                            {t(`button.logout`)}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

Navbar.defaultProps = {
    avatar: null,
    cart: [],
    onLogoClick: () => { },
    onUpAndRunningClick: () => { },
    onAccountBoxClick: () => { },
    onShoppingCartClick: () => { },
    onLogoutClick: () => { },
    onProcessesClick: () => { },
    onManagementClick: () => { },
    onHardwareFinderClick: () => { },
    onMobilityClick: () => { },
    onSupportPortalClick: () => { },
    onPriceModelClick: () => { },
    megaDropdownComponents: <></>,
    onChangeLanguageClick: () => { }
}

export default React.memo(Navbar)