import React from 'react';

import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import { useStyles } from './RegisterSuccessDialogStyles';
import { ColorSAP, FONT_FAMILY, Typography } from '@commsult/ontego-ui';
import { useTranslation } from 'react-i18next';

const RegisterSuccessDialog = props => {
    const { open, onClose } = props
    const classes = useStyles(props)

    const { t } = useTranslation();

    const handleClose = () => {
        onClose()
    }

    return (
        <DialogBox
            open={open}
            handleClose={handleClose}
            textTitle={t("register.successDialog.title")}
            content={
                <div style={{ position: "relative", padding: "0px 24px 40px 24px", display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 588 }}> 
                    <div style={{   display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 510, marginTop: 64, marginBottom: 60 }}>
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 32C1.5 15.1553 15.1553 1.5 32 1.5C48.8447 1.5 62.5 15.1553 62.5 32C62.5 48.8447 48.8447 62.5 32 62.5C15.1553 62.5 1.5 48.8447 1.5 32Z" stroke="#00AE6F" stroke-width="3"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M46.5119 22.4882C47.1628 23.139 47.1628 24.1943 46.5119 24.8452L29.8453 41.5118C29.1944 42.1627 28.1391 42.1627 27.4882 41.5118L19.1549 33.1785C18.504 32.5276 18.504 31.4724 19.1549 30.8215C19.8058 30.1706 20.8611 30.1706 21.5119 30.8215L28.6667 37.9763L44.1549 22.4882C44.8058 21.8373 45.8611 21.8373 46.5119 22.4882Z" fill="#00AE6F"/>
                        </svg>

                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                            size={18}
                            color={ColorSAP.neutral[100]}
                            style={{ lineHeight: "25px", marginTop: 24, textAlign: "center" }}
                        >
                            {t("register.successDialog.message")}
                        </Typography>
                    </div>
                </div>
            }
        />
    )
};

export default RegisterSuccessDialog;