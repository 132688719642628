import { Color, FUNCTION_TYPE } from "@commsult/ontego-ui";
import Function from "../Models/Function";

export const WAREHOUSE_LOGISTICS = `WAREHOUSE_LOGISTICS`
export const PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE = `PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE`
export const MOBILE_INVENTORY_SOLUTIONS = `MOBILE_INVENTORY_SOLUTIONS`
export const SONDERFUNKTION = `SONDERFUNKTION`

/** TEXT LINK */
export const INVENTUR_LINK = `<span style="cursor: pointer; color: ${Color.primary[400]}" onClick="window.xx('INVENTUR')"><b>Inventur</b></span>`
export const EINLAGERUNG_LINK = `<span style="cursor: pointer; color: ${Color.primary[400]}" onClick="window.xx('EINLAGERUNG')"><b>Einlagerung</b></span>`
export const BESTELLUNG_LINK = `<span style="cursor: pointer; color: ${Color.primary[400]}" onClick="window.xx('BESTELLUNG')"><b>Bestellung</b></span>`
export const BESTANDSINFO_LINK = `<span style="cursor: pointer; color: ${Color.primary[400]}" onClick="window.xx('BESTANDSINFO')"><b>Bestandsinfo</b></span>`
export const KOMMISSIONIERUNG_LINK = `<span style="cursor: pointer; color: ${Color.primary[400]}" onClick="window.xx('KOMMISSIONIERUNG')"><b>Kommissionierung</b></span>`
export const UMLAGERUNG_LINK = `<span style="cursor: pointer; color: ${Color.primary[400]}" onClick="window.xx('UMLAGERUNG')"><b>Umlagerung</b></span>`
export const AUSLAGERUNG_LINK = `<span style="cursor: pointer; color: ${Color.primary[400]}" onClick="window.xx('AUSLAGERUNG')"><b>Auslagerung</b></span>`
export const WARENAUSGANG_INTERN_LINK = `<span style="cursor: pointer; color: ${Color.primary[400]}" onClick="window.xx('WARENAUSGANG_INTERN')"><b>Warenausgang Intern</b></span>`
export const KANBAN_STATUSWECHSEL_LINK = `<span style="cursor: pointer; color: ${Color.primary[400]}" onClick="window.xx('KANBAN_STATUSWECHSEL')"><b>Kanban-Statuswechsel</b></span>`


/**
 * MOBILE WAREHOUSE LOGISTICS SOLUTIONS
 */
export const FUNCTION_WARENEINGANG = new Function(
    "F_WARENEINGANG",
    "Wareneingang",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_EINLAGERUNG = new Function(
    "F_EINLAGERUNG",
    "Einlagerung",
    [FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_UMLAGERUNG = new Function(
    "F_UMLAGERUNG",
    "Umlagerung",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_UMBUCHUNG = new Function(
    "F_UMBUCHUNG",
    "Umbuchung",
    [FUNCTION_TYPE.MM],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_AUSLAGERUNG = new Function(
    "F_AUSLAGERUNG",
    "Auslagerung",
    [FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_KOMMISSIONERUNG = new Function(
    "F_KOMMISSIONIERUNG",
    "Kommissionierung",
    [FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_WARENAUSGANG_INTERN = new Function(
    "F_WARENAUSGANG_INTERN",
    "Warenausgang intern",
    [FUNCTION_TYPE.MM],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_WARENAUSGANG_EXTERN = new Function(
    "F_WARENAUSGANG_EXTERN",
    "Warenausgang extern",
    [FUNCTION_TYPE.SD],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_HU_MANAGEMENT = new Function(
    "F_VERPACKUNG",
    "Verpackung",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.SD],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_BESTANDSINFO = new Function(
    "F_BESTANDSINFO",
    "Bestandsinfo",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_VERLADUNG = new Function(
    "F_VERLADUNG",
    "Verladung",
    [FUNCTION_TYPE.SD],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_BESTELLERFASSUNG = new Function(
    "F_BESTELLERFASSUNG",
    "Bestellung",
    [FUNCTION_TYPE.MM],
    WAREHOUSE_LOGISTICS,
    []
)

export const FUNCTION_QUALITAETS_MANAGEMENT_MOBILE_WAREHOUSE_LOGISTICS_SOLUTIONS = new Function(
    "F_QUALITAETSKONTROLLE_MANAGEMENT_MOBILE_WAREHOUSE_LOGISTICS_SOLUTIONS",
    "QualitaetskontrolleMobileWarehouseLogisticsSolutions",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM, FUNCTION_TYPE.QM],
    WAREHOUSE_LOGISTICS,
    []
)


/**
 * MOBILE PRODUCTION SOLUTIONS
 */
export const FUNCTION_PRODUKTIONSVERSORGUNG = new Function(
    "F_PRODUKTIONSVERSORGUNG",
    "Produktionsversorgung",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM],
    PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE,
    []
)

export const FUNCTION_KANBAN = new Function(
    "F_KANBAN",
    "Kanban",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.PP],
    PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE,
    []
)

export const FUNCTION_RUECKMELDUNG_PRODUKTION = new Function(
    "F_RUECKMELDUNG_PRODUKTION",
    "Produktionsrückmeldung",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.PP, FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM],
    PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE,
    []
)

export const FUNCTION_QUALITAETS_MANAGEMENT = new Function(
    "F_QUALITAETSKONTROLLE",
    "Qualitaetskontrolle",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM, FUNCTION_TYPE.QM],
    PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE,
    []
)

export const FUNCTION_VERPACKUNG_PRODUKTION = new Function(
    "F_VERPACKUNG_PRODUKTION",
    "VerpackungProduktion",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.SD],
    PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE,
    []
)

/**
 * MOBILE INVENTORY SOLUTIONS
 */
export const FUNCTION_INVENTUR = new Function(
    "F_INVENTUR",
    "Inventur",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM],
    MOBILE_INVENTORY_SOLUTIONS,
    []
)

export const FUNCTION_BESTANDSINFO_MOBILE_INVENTORY_SOLUTIONS = new Function(
    "F_BESTANDSINFO_MOBILE_INVENTORY_SOLUTIONS",
    "BestandsinfoMobileInventorySolutions",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.WM, FUNCTION_TYPE.EWM],
    MOBILE_INVENTORY_SOLUTIONS,
    []
)

export const FUNCTION_INVENTUR_LEITSTAND = new Function(
    "F_INVENTUR_LEITSTAND",
    "InventurLeitstand",
    [FUNCTION_TYPE.MM, FUNCTION_TYPE.WM],
    MOBILE_INVENTORY_SOLUTIONS,
    []
)

export const FUNCTION_SONDERFUNKTIONEN_ERWEITERUNGEN = new Function(
    "F_SONDERFUNKTIONEN_ERWEITERUNGEN",
    "Sonderfunktionen & Erweiterungen",
    [],
    SONDERFUNKTION,
    []
)