import React, { useState, useContext, useCallback, useEffect } from 'react'
import { OntegoSAPLogoDefault, Typography, FONT_FAMILY, Color, ColorSAP } from '@commsult/ontego-ui'
import { Link, useHistory } from "react-router-dom";

import { AppContext } from '../../App'
import { useStyles } from './LoginStyles';

import Grid from '@material-ui/core/Grid';

import LoginImage from "../../assets/img/login/login.jpg";

import moment from 'moment';
import { SCENARIO_PATH, ADMIN_DASHBOARD, REGISTER_PATH } from '../../Constants/Path';
import { timeout } from '../../Utils/Utils';
import { ROLE_ADMIN } from '../../Constants/ClientRoles';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import Button from '../../ReusableComponents/Button/Button';
import Form from '../../ReusableComponents/Form/Form';
import { LogoDark } from '../../assets/img/logo/logoDark';
import Play from '../../Icons/Play';
import VideoDialog from '../../Dialogs/VideoDialog/VideoDialog';
import { Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { LanguageSwitcher } from '../../ReusableComponents/LanguageSwitcher/LanguageSwitcher';
import OntegoCockpitThumbnail from '../../assets/videos/thumbnail-ontego-cockpit.webm'

const Login = props => {
    const classes = useStyles(props);
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [emailError, setEmailError] = useState(false)
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [passwordError, setPasswordError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");

    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);

    const { t, i18n } = useTranslation();
    
    const theme = useTheme();
    const isMobileDisplay = useMediaQuery(theme.breakpoints.down('xs'));
    const isMobileTabletDisplay = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        setIsLoginLoading,
        AuthService,
        setUserInfo,
        handleShowErrorSnackbar
    } = useContext(AppContext);

    const onChangeLanguageClick = (lang) => {
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", lang)
    };

    const handleChangeEmail = e => {
        setEmail(e.currentTarget.value)
        setEmailError(false)
        setEmailInvalid(false)
        setErrorMessage("")
    };

    const handleChangePassword = e => {
        setPassword(e.currentTarget.value)
        setPasswordError(false)
        setErrorMessage("")
    };

    const handleUserKeyPress = useCallback(event => {
        const { keyCode } = event;

        if (keyCode === 13) {
            if (document.getElementById('loginButton')) document.getElementById('loginButton').click()
        }
    }, []);

    const handlePlayVideo = () => {
        setIsVideoDialogOpen(true)
    }

    const handleCloseVideo = () => {
        setIsVideoDialogOpen(false)
    }

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        console.log('userAgent', userAgent)
    }, [])

    const login = async e => {
        e.preventDefault();
        setIsLoginLoading(true)

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailRegex.test(email) || email === "" || password === "") {
            setEmailError(!email)
            setEmailInvalid(!emailRegex.test(email))
            setPasswordError(!password)
            setIsLoginLoading(false)
            return;
        }

        const credentials = { email: email, password: password, name: "NAME TEST" };
        AuthService.login(credentials).then(res => {
            if (res.data.login) {
                localStorage.setItem("userInfo", JSON.stringify(res.data.token));

                AuthService.setUserToken(res.data.token);

                const decoded = jwtDecode(res.data.token);

                // Set current user
                setUserInfo(decoded);
                setIsLoginLoading(false)

                if (decoded.role === ROLE_ADMIN) {
                    history.push(ADMIN_DASHBOARD)
                } else {
                    history.push(SCENARIO_PATH)
                }
            } else {
                if (res.data.msg) {
                    console.log(res.data.msg)
                    setErrorMessage(res.data.msg);
                } else {
                    setErrorMessage(t("error.emailPasswordWrong"));
                }

                setEmail("")
                setPassword("")
                setIsLoginLoading(false)
            }
        }, error => {
            console.log(error)
            setEmail("")
            setPassword("")
            setErrorMessage(t("error.internalErrorLogin"));
            setIsLoginLoading(false)
        }).catch(err => {
            console.log(err);
            handleShowErrorSnackbar(err);
        });
        await timeout(2000)
        setIsLoginLoading(false)
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                    <Grid container direction="row-reverse" style={{ height: isMobileTabletDisplay ? "auto" : "100%" }}>
                        <Hidden xsDown>
                            <Grid 
                                style={{position: 'relative'}}
                                item xs={12}
                                sm={12} 
                                md={6}
                            >
                                <div className={classes.videoThumbnailContainer}>
                                    <video
                                        className={classes.videoThumbnail}
                                        playsInline
                                        autoPlay
                                        muted
                                        loop
                                        fetchPriority="low"
                                        preload="none"
                                    >
                                        <source
                                            src={OntegoCockpitThumbnail}
                                            codecs="vp8, vorbis"
                                        />
                                    </video>
                                </div>
                                <div className={classes.videoContainer}>
                                    <div
                                        className={classes.playButton}
                                        onClick={handlePlayVideo}
                                    >
                                        <Play style={{ width: 80, height: 80, color: ColorSAP.neutral[0] }} />
                                    </div>
                                    <Hidden xsDown>
                                        <div style={{ position: "absolute", top: isMobileTabletDisplay ? 40 : 64, right: isMobileTabletDisplay ? 48 : 120 }}>
                                            <LanguageSwitcher
                                                i18n={i18n}
                                                onChangeLanguageClick={onChangeLanguageClick}
                                                theme="light"
                                            />
                                        </div>
                                    </Hidden>
                                </div>
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid item sm={12}>
                                <div style={{ float: "inline-end", margin: "40px 24px 24px 24px" }}>
                                    <LanguageSwitcher
                                        i18n={i18n}
                                        onChangeLanguageClick={onChangeLanguageClick}
                                        theme="dark"
                                    />
                                </div>
                            </Grid>
                        </Hidden>
                        <Grid item sm={12} md={6}>
                            <Grid container style={{ height: "100%" }}>
                                <Grid item xs={12}>
                                    <div id="loginContainer" className={classes.loginContainer}>
                                        <Grid container>
                                            <Grid item xs={12} style={{ marginBottom: isMobileDisplay ? 4 : 24 }}>
                                                <Hidden smDown>
                                                    <LogoDark
                                                        style={{
                                                            width: 158,
                                                            height: 64,
                                                            marginBottom: 56
                                                        }}
                                                    />
                                                </Hidden>
                                                <Typography
                                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                                    size={isMobileDisplay ? 32 : 56}
                                                    color={ColorSAP.neutral[100]}
                                                    className={classes.title}
                                                >
                                                    {t("login.info.title")}
                                                </Typography>

                                                <Typography
                                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                    size={14}
                                                    color={ColorSAP.neutral[100]}
                                                    className={classes.description}
                                                >
                                                    {t("login.info.desc")}
                                                </Typography>

                                                <Typography
                                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                    size={14}
                                                    color={ColorSAP.neutral[100]}
                                                    className={classes.description}
                                                >
                                                    <span>
                                                        {t("login.info.desc2")}
                                                    </span>
                                                    <span
                                                        style={{ color: ColorSAP.primary[70], cursor: 'pointer' }}
                                                        onClick={handlePlayVideo}
                                                    >
                                                        {t("login.info.link")}
                                                    </span>
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} style={{ maxWidth: 488, marginBottom: isMobileDisplay ? 32 : 48 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <div tabIndex={1}>
                                                            <Form
                                                                placeholder={`${t('common.email')}`}
                                                                label={`${t('common.email')} *`}
                                                                onChange={handleChangeEmail}
                                                                value={email}
                                                                error={emailError || emailInvalid}
                                                                errorMessage={emailError ? t("error.email") : t("error.emailInvalid")}
                                                            />
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <div tabIndex={2}>
                                                            <Form
                                                                placeholder={`${t('common.password')}`}
                                                                label={`${t('common.password')} *`}
                                                                onChange={handleChangePassword}
                                                                isPassword
                                                                value={password}
                                                                error={passwordError}
                                                                errorMessage={t("error.password")}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    {errorMessage !== "" && (
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                                size={12}
                                                                color={ColorSAP.danger[50]}
                                                            >
                                                                {errorMessage}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div tabIndex={3}>
                                                    <Button
                                                        id="loginButton"
                                                        type="primary"
                                                        theme="dark"
                                                        size={isMobileTabletDisplay ? "small" : "medium"}
                                                        onClick={login}
                                                    >
                                                        {t("login.login")}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Hidden smDown>
                                    <Grid item xs={12} style={{ display: "flex", alignItems: "end" }}>
                                        <div className={classes.footerContainer}>
                                            <Typography
                                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_LIGHT}
                                                size={14}
                                                color={ColorSAP.neutral[100]}
                                                className={classes.lineHeight22}
                                            >
                                                {t("login.dontHaveAcc")}
                                            </Typography>
                                            <Link
                                                to={REGISTER_PATH} style={{ textDecoration: "none" }}
                                            >
                                                <Typography
                                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                                    size={14}
                                                    color={ColorSAP.primary[70]}
                                                    className={classes.lineHeight22}
                                                    style={{ marginLeft: 10, cursor: "pointer" }}
                                                >
                                                {t("login.register")}
                                                </Typography>
                                            </Link>
                                        </div>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Hidden mdUp>
                    <Grid item xs={12} style={{ display: "flex", alignItems: "end" }}>
                        <div className={classes.footerContainer}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_LIGHT}
                                size={14}
                                color={ColorSAP.neutral[100]}
                                className={classes.lineHeight22}
                            >
                                {t("login.dontHaveAcc")}
                            </Typography>
                            <Link
                                to={REGISTER_PATH} style={{ textDecoration: "none" }}
                            >
                                <Typography
                                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                                    size={14}
                                    color={ColorSAP.primary[70]}
                                    className={classes.lineHeight22}
                                    style={{ marginLeft: 10, cursor: "pointer" }}
                                >
                                {t("login.register")}
                                </Typography>
                            </Link>
                        </div>
                    </Grid>
                </Hidden>
            </Grid>
            <VideoDialog
                open={isVideoDialogOpen}
                onClose={handleCloseVideo}
            />
        </div>
    )
}

export default Login;