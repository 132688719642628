// Material-UI Styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// Constants
import { Color, ColorSAP } from '@commsult/ontego-ui';

export const useStyles = makeStyles(theme =>
    createStyles({
        dialogSize: {
            [theme.breakpoints.between('1280', '1440')]: {
                width: 1200,
            },
            [theme.breakpoints.up('1440')]: {
                width: '90vw',
            },
        },
        functionListCardsContainer: {
            // width: '20%',
            // height: '75vh',
            // float: 'left'
            // border: '1px solid red',
            maxWidth: 365,
            paddingRight: 12,
            boxSizing: 'border-box'
        },
        functionListContainerTitle: {
            width: '95%'
        },
        functionListContainer: {
            display: 'flex',
            alignItems: 'start',
            width: '100%',
            marginTop: 20,
        },
        functionList: {
            flex: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            overflow: '-moz-scrollbars-vertical',
            "&::-webkit-scrollbar": {
                webkitAppearance: 'none',
                width: 7
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: 8,
                backgroundColor: Color.neutral[300],
                webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)'
            }
        },
        functionListItems: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'start',
            // marginRight: '1%'
        },

        upAndRunningCardsContainer: {
            border: `1px solid ${ColorSAP.primary[20]}`,
            flexGrow: 1,
            // padding: 24,
            borderRadius: 16,
            background: ColorSAP.primary[10],
            display: '-webkit-inline-box',
            overflowX: 'auto',
            overflowY: 'auto',
            marginLeft: 16,
        },
        upAndRunningCardsContainerEmpty: {
            flexGrow: 1,
            borderRadius: 16,
            background: ColorSAP.primary[10],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowX: 'auto',
            marginLeft: 16,
        },
        upAndRunningListContainerEmpty: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            // width: '100%'
        },
        upAndRunningListContainer: {
            position: 'relative',
            display: 'flex',
            alignItems: 'start',
            // width: '100%',
            padding: 24
        },
        upAndRunningList: {
            flex: '100%',
            display: 'flex',
            flexDirection: 'column',
            // overflowY: 'auto',
            // overflow: '-moz-scrollbars-vertical',
            // maxHeight: 'calc(48vh - 72px)',
            height: 'fit-content',
            "&::-webkit-scrollbar": {
                webkitAppearance: 'none',
                width: 7
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: 8,
                backgroundColor: Color.neutral[300],
                webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)'
            }
        },
        upAndRunningListItems: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'start',
            marginRight: '2%'
        },
    })
)