// React lib

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext, PaddedContainer, RootWrapperContainer } from '../../App';

import classnames from 'classnames';

// Styles
import { useStyles } from './FunctionDetailsContainerStyles';

// @otg-one/ui_components/components
import { Color, ColorSAP, Delete, FONT_FAMILY, Search, Typography } from '@commsult/ontego-ui';

import { ArrowLeft, ArrowRight, CollectionAdded, Copy, ProcessOverview } from '@commsult/ontego-ui/dist/sap_icons';

// Material-UI Core
import Grid from '@material-ui/core/Grid';

// Reusable Components
import DeleteIconButton from '../../ReusableComponents/DeleteIconButton';
import FunctionTypeChips from '../../ReusableComponents/FunctionTypeChips';

// Components
import DeleteDialog from '../../Dialogs/DeleteDialog/DeleteDialog';
import SpecialFunctionDialog from '../../Dialogs/SpecialFunctionDialog/SpecialFunctionDialog';
import FunctionLists from './FunctionLists';

// Models
import Cart from '../../Models/Cart';
import CartProcess from '../../Models/CartProcess';

// Constants
import { SCENARIO_PATH } from '../../Constants/Path';

// Utils
import { convertNotesToEditorState, convertNotesToJSON, isProcessAlreadyAddedToCollection, isProcessTypeAlreadyAddedToCollection } from '../../Utils/Utils';

import { useTranslation } from 'react-i18next';

// Service
import AppService from '../../AppService';
import { F_AUSLAGERUNG, F_BESTANDSINFO, F_BESTELLERFASSUNG, F_EINLAGERUNG, F_INVENTUR, F_KANBAN, F_KOMMISSIONIERUNG, F_PRODUKTIONSVERSORGUNG, F_QUALITAETSKONTROLLE, F_RUECKMELDUNG_PRODUKTION, F_SONDERFUNKTIONEN_ERWEITERUNGEN, F_UMBUCHUNG, F_UMLAGERUNG, F_VERLADUNG, F_VERPACKUNG, F_WARENAUSGANG_EXTERN, F_WARENAUSGANG_INTERN, F_WARENEINGANG } from '../../Constants/FunctionIds';
import { getFunctionIconFromId, getTypeOfFunction } from '../../Utils/FunctionUtils';
import FunctionService from './FunctionService';

import { AuslagerungImages } from '../../Utils/FunctionImages/AuslagerungImages';
import { BestandsinfoImages } from '../../Utils/FunctionImages/BestandsinfoImages';
import { BestellerfassungImages } from '../../Utils/FunctionImages/BestellerfassungImages';
import { EinlagerungImages } from '../../Utils/FunctionImages/EinlagerungImages';
import { InventurImages } from '../../Utils/FunctionImages/InventurImages';
import { KanbanImages } from '../../Utils/FunctionImages/KanbanImages';
import { KommissionierungImages } from '../../Utils/FunctionImages/KommissionierungImages';
import { ProduktionsversorgungImages } from '../../Utils/FunctionImages/ProduktionsversorgungImages';
import { QualitaetskontrolleImages } from '../../Utils/FunctionImages/QualitaetskontrolleImages';
import { RueckmeldungproduktionImages } from '../../Utils/FunctionImages/RueckmeldungproduktionImages';
import { UmbuchungImages } from '../../Utils/FunctionImages/UmbuchungImages';
import { UmlagerungImages } from '../../Utils/FunctionImages/UmlagerungImages';
import { VerladungImages } from '../../Utils/FunctionImages/VerladungImages';
import { VerpackungImages } from '../../Utils/FunctionImages/VerpackungImages';
import { WarenausgangExternImages } from '../../Utils/FunctionImages/WarenausgangExternImages';
import { WarenausgangInternImages } from '../../Utils/FunctionImages/WarenausgangInternImages';
import { WareneingangImages } from '../../Utils/FunctionImages/WareneingangImages';

import { AuslagerungImagesEN } from '../../Utils/FunctionImagesEN/AuslagerungImages';
import { BestandsinfoImagesEN } from '../../Utils/FunctionImagesEN/BestandsinfoImages';
import { BestellerfassungImagesEN } from '../../Utils/FunctionImagesEN/BestellerfassungImages';
import { EinlagerungImagesEN } from '../../Utils/FunctionImagesEN/EinlagerungImages';
import { InventurImagesEN } from '../../Utils/FunctionImagesEN/InventurImages';
import { KanbanImagesEN } from '../../Utils/FunctionImagesEN/KanbanImages';
import { KommissionierungImagesEN } from '../../Utils/FunctionImagesEN/KommissionierungImages';
import { ProduktionsversorgungImagesEN } from '../../Utils/FunctionImagesEN/ProduktionsversorgungImages';
import { QualitaetskontrolleImagesEN } from '../../Utils/FunctionImagesEN/QualitaetskontrolleImages';
import { RueckmeldungproduktionImagesEN } from '../../Utils/FunctionImagesEN/RueckmeldungproduktionImages';
import { UmbuchungImagesEN } from '../../Utils/FunctionImagesEN/UmbuchungImages';
import { UmlagerungImagesEN } from '../../Utils/FunctionImagesEN/UmlagerungImages';
import { VerladungImagesEN } from '../../Utils/FunctionImagesEN/VerladungImages';
import { VerpackungImagesEN } from '../../Utils/FunctionImagesEN/VerpackungImages';
import { WarenausgangExternImagesEN } from '../../Utils/FunctionImagesEN/WarenausgangExternImages';
import { WarenausgangInternImagesEN } from '../../Utils/FunctionImagesEN/WarenausgangInternImages';
import { WareneingangImagesEN } from '../../Utils/FunctionImagesEN/WareneingangImages';

import { FUNCTION_SONDERFUNKTIONEN_ERWEITERUNGEN } from '../../Constants/FunctionCollection';

import Carousel from '../../ReusableComponents/Carousel/Carousel';
import Drawer from '../../ReusableComponents/Drawer/Drawer';
import Form from '../../ReusableComponents/Form/Form';
// import { ArrowBack } from '@material-ui/icons';
import Breadcrumb from '../../ReusableComponents/Breadcrumb/Breadcrumb';
import Button from '../../ReusableComponents/Button/Button';

// import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import Tooltip from '../../ReusableComponents/Tooltip/Tooltip';
import useScreenSize from '../../Utils/useScreenSize';

const AppPreview = props => {
    const classes = useStyles(props)
    // const { inspectedFunctionId, specialFunctions } = useContext(AppContext)
    const { inspectedFunctionImage, activeData, appPreviewNavIndex, setAppPreviewNavIndex, handleCarouselNavOnClick, handleLeftArrowOnClick, handleRightArrowOnClick } = props

    // const screenSize = useScreenSize()

    const appPreviewContainerClasses = classnames({
        [classes.appPreviewContainer]: true
    })

    let imagesLength = 0
    let shownImage = ''
    let shownImageName = ''
    let showCarousel = false

    if (inspectedFunctionImage && typeof inspectedFunctionImage.filter(inspectedImage => inspectedImage.id === activeData.dataType[activeData.activeIndex].systemID)[0] !== 'undefined') {
        imagesLength = inspectedFunctionImage.filter(inspectedImage => inspectedImage.id === activeData.dataType[activeData.activeIndex].systemID)[0].images.length
        if (imagesLength > 0) {
            if (typeof inspectedFunctionImage.filter(inspectedImage => inspectedImage.id === activeData.dataType[activeData.activeIndex].systemID)[0].images[appPreviewNavIndex] !== 'undefined') {
                showCarousel = true
                shownImage = inspectedFunctionImage.filter(inspectedImage => inspectedImage.id === activeData.dataType[activeData.activeIndex].systemID)[0].images[appPreviewNavIndex].image
                shownImageName = inspectedFunctionImage.filter(inspectedImage => inspectedImage.id === activeData.dataType[activeData.activeIndex].systemID)[0].images[appPreviewNavIndex].name
            }
            else {
                showCarousel = true
                shownImage = inspectedFunctionImage.filter(inspectedImage => inspectedImage.id === activeData.dataType[activeData.activeIndex].systemID)[0].images[0].image
                shownImageName = inspectedFunctionImage.filter(inspectedImage => inspectedImage.id === activeData.dataType[activeData.activeIndex].systemID)[0].images[0].name
                setAppPreviewNavIndex(0)
            }
        }
    }

    return (
        <div
            className={appPreviewContainerClasses}
        >
            <div className={classes.appPreviewWrapper}>
                <div
                    className={appPreviewNavIndex > 0 ? classes.carouselArrow : classes.disabledCarouselArrow}
                    style={{ cursor: appPreviewNavIndex > 0 ? 'pointer' : "", position: 'absolute', top: '45%', left: -42, width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    onClick={handleLeftArrowOnClick}
                >
                    <ArrowLeft />
                </div>
                <div className={classes.appPreviewImageContainer}>
                    <img
                        width="100%"
                        src={shownImage}
                        alt={shownImage}
                        style={{
                            borderRadius: 8,
                            marginBottom: -4
                        }}
                    />
                    <div className={classes.appPreviewTextContainer}>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM}
                            size={12}
                            color={ColorSAP.neutral[50]}
                            style={{ marginRight: activeData.dataType[activeData.activeIndex].type.length > 0 && 8 }}
                        >
                            {shownImageName}
                        </Typography>
                        <FunctionTypeChips type={activeData.dataType[activeData.activeIndex].type} />
                    </div>
                </div>
                {imagesLength > 1 && (
                    <div
                        className={appPreviewNavIndex + 1 === imagesLength ? classes.disabledCarouselArrow : classes.carouselArrow}
                        style={{ cursor: appPreviewNavIndex + 1 === imagesLength ? "" : 'pointer', position: 'absolute', top: '45%', right: -42, width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        onClick={handleRightArrowOnClick}
                    >
                        <ArrowRight />
                    </div>
                )}
                {(showCarousel && imagesLength > 1) && (
                    <div className={classes.carouselContainer}>
                        <Carousel
                            length={imagesLength}
                            index={appPreviewNavIndex}
                            onClick={handleCarouselNavOnClick}
                        />
                    </div>
                )}
            </div>
        </div >
    )
}

const Sidebar = props => {
    const classes = useStyles(props)

    const { collection, inspectedFunctionId } = useContext(AppContext)

    const { originalMenus, menus, search, onSearch, onClear, activeOption, setActiveOption, setActiveData, activeData, isScrolling, setIsScrolling, setAppPreviewNavIndex, handleOpenSpecialFunctionDialog } = props

    const { t } = useTranslation();

    const screenSize = useScreenSize()

    return (
        <div className={classes.sidebarContainer}>
            {originalMenus.length > 0 && (
                <Form
                    placeholder={t("common.search")}
                    onChange={onSearch}
                    style={{ outline: 'none' }}
                    startAdornment={<Search />}
                // startAdornment={<SearchIcon style={{ fontSize: 16 }} />}
                />

            )}
            {menus.length > 0 && (
                <div
                    className={classes.sidebarDrawerContainer}
                    style={{
                        maxHeight: `calc(${screenSize.height - 280}px)`,
                    }}
                >
                    {inspectedFunctionId === F_SONDERFUNKTIONEN_ERWEITERUNGEN && (
                        <div
                            style={{ marginBottom: 30 }}
                        >
                            <NewFunctionButton onClick={handleOpenSpecialFunctionDialog} size="small" />
                        </div>
                    )}
                    <Drawer
                        inspectedFunctionId={inspectedFunctionId}
                        menus={menus}
                        activeOption={activeOption}
                        setActiveOption={setActiveOption}
                        setActiveData={setActiveData}
                        isProcessTypeAlreadyAddedToCollection={isProcessTypeAlreadyAddedToCollection}
                        collection={collection}
                        isScrolling={isScrolling}
                        setIsScrolling={setIsScrolling}
                        setAppPreviewNavIndex={setAppPreviewNavIndex}
                    />
                </div>
            )}
        </div>
    )
}

const NewFunctionButton = props => {
    const { onClick, size } = props
    const { t } = useTranslation()

    return (
        <Button
            type="primary"
            theme="dark"
            size={size}
            style={{ outline: "none" }}
            onClick={onClick}
        >
            {t("functionDetails.newFunction")}
        </Button>
    )
}

const AddCollectionButton = props => {

    const { handleAddToCollection, activeData } = props

    const { t } = useTranslation();

    return (
        <>
            <Button
                type="secondary"
                size="small"
                theme="light"
                onClick={handleAddToCollection(activeData)}
            >
                {t("functionDetails.addToCollection").toUpperCase()}
            </Button>
        </>
    )
}

const RemoveCollectionButton = props => {

    const { handleRemoveFromCollection, activeData } = props

    const { t } = useTranslation();

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <CollectionAdded style={{ width: 24, height: 24, color: ColorSAP.secondary[40] }} />
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                    size={14}
                    color={ColorSAP.neutral[100]}
                    style={{ marginLeft: 8 }}
                >
                    {t("functionDetails.added")}
                </Typography>
            </div>
            <Tooltip title={t('functionDetails.removeFromCollection')} placement="bottom">
                <div>
                    <DeleteIconButton
                        onClick={handleRemoveFromCollection(activeData)}
                    />
                </div>
            </Tooltip>
        </>
    )
}

const ConfigurationContainer = props => {
    const classes = useStyles(props);
    const history = useHistory();

    const { setIsFunctionLoading, inspectedFunctionId, collection, setCollection, userInfo, specialFunctions, setSpecialFunctions, handleShowSnackbar, handleShowErrorSnackbar, editorStateList, setEditorStateList, handleAddEditorStateList } = useContext(AppContext)

    const [activeData, setActiveData] = useState(null)
    const [activeOption, setActiveOption] = useState("")

    const [inspectedFunction, setInspectedFunction] = useState(null)
    const [inspectedFunctionImage, setInspectedFunctionImage] = useState(null)
    const [searchableInspectedFunction, setSearchableInspectedFunction] = useState(null)

    const [appPreviewNavIndex, setAppPreviewNavIndex] = useState(0)

    const [isScrolling, setIsScrolling] = useState(false)

    const [search, setSearch] = useState("")

    const [specialFunctionDialogOpen, setSpecialFunctionDialogOpen] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const [isEditingSpecialFunction, setIsEditingSpecialFunction] = useState(false)
    const [inspectedSpecialFunctionData, setInspectedSpecialFunctionData] = useState(null)

    useEffect(() => {
        if (inspectedFunction) {
            let isCancelled = false
            let isError = false

            const fetchData = async () => {
                if (!isCancelled) {
                    const fetchedFunctionCollection = await AppService.getFunctionCollection(userInfo.key).catch(err => {
                        console.log(err)
                        isError = true
                    });

                    if (!isError) {
                        const newEditorStateList = [...editorStateList]

                        const newCollection = convertNotesToEditorState(fetchedFunctionCollection.data.functionCollection)

                        newCollection.forEach(collection => {
                            if (collection.systemID === inspectedFunction.systemID) {
                                collection.processes.forEach(process => {
                                    newEditorStateList.push({
                                        function_id: inspectedFunction.systemID,
                                        sub_function_id: process.systemID,
                                        notes: process.notes
                                    })
                                })
                            }
                        })

                        setEditorStateList(newEditorStateList)
                    }
                }
            }

            fetchData()

            return () => {
                isCancelled = true
            }
        }
    }, [inspectedFunction, collection])

    const { t, i18n } = useTranslation();

    const handleOpenSpecialFunctionDialog = () => {
        setSpecialFunctionDialogOpen(true)
    }

    const handleCloseSpecialFunctionDialog = () => {
        setSpecialFunctionDialogOpen(false)
        setIsEditingSpecialFunction(false)
        setInspectedSpecialFunctionData(null)
    }

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true)
    }
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false)
        setInspectedSpecialFunctionData(null)
    }

    const handleDeleteSpecialFunction = () => {
        // console.log("HANDLE DELETE SPECIAL FUNCTION")

        const newSpecialFunctions = { ...specialFunctions }
        const tempSpecialFunctions = JSON.parse(JSON.stringify(newSpecialFunctions))

        const newCollection = [...collection]
        const tempCollection = JSON.parse(JSON.stringify(newCollection))


        newSpecialFunctions.data.forEach((p, j) => {
            if (p.name === inspectedSpecialFunctionData.name && JSON.stringify(p.type) === JSON.stringify(inspectedSpecialFunctionData.type)) {
                newSpecialFunctions.data.splice(j, 1)
            }
        })

        if (isProcessAlreadyAddedToCollection(collection, inspectedSpecialFunctionData)) {

            newCollection.forEach((c, i) => {
                if (c.name === inspectedFunction.name) {
                    c.processes.forEach((p, j) => {
                        if (p.name === inspectedSpecialFunctionData.name) {
                            if (JSON.stringify(p.type) === JSON.stringify(inspectedSpecialFunctionData.dataType[inspectedSpecialFunctionData.activeIndex].type)) {
                                c.processes.splice(j, 1)
                            }

                            if (c.processes.length === 0) {
                                newCollection.splice(i, 1)
                            }
                        }
                    })
                }
            })
        }

        AppService.updateSpecialFunctions(userInfo.key, newSpecialFunctions)
            .then(res => setSpecialFunctions(newSpecialFunctions))

        AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(newCollection)).then(res => {
            setCollection(convertNotesToEditorState(newCollection))
            handleShowSnackbar({
                message: t("manage.objectDeleted", { object: inspectedSpecialFunctionData.name }),
                autoHideDuration: 5000,
                icon: <Delete />,
                showButton: true,
                buttonLabel: t("manage.undo"),
                onUndo: () => {
                    setSpecialFunctions(tempSpecialFunctions)
                    setCollection(tempCollection)
                }
            });
        }).catch(err => {
            handleShowErrorSnackbar(err);
        });
    }

    useEffect(() => {
        let isCancelled = false

        const fetchData = async () => {
            setIsFunctionLoading(true)

            if (inspectedFunctionId !== F_SONDERFUNKTIONEN_ERWEITERUNGEN)
                await new Promise(resolve => setTimeout(resolve, 500))

            const fetchedFunction = await FunctionService.getFunctionData(inspectedFunctionId).catch(err => {
                setIsFunctionLoading(false);
                handleShowErrorSnackbar(err);
            });

            let newFunctionData;
            let obj = null;

            if (!isCancelled) {
                if (inspectedFunctionId === F_SONDERFUNKTIONEN_ERWEITERUNGEN) {
                    newFunctionData = specialFunctions
                } else {
                    newFunctionData = { ...fetchedFunction.data }
                }

                switch (newFunctionData.systemID) {
                    case F_AUSLAGERUNG:
                        obj = i18n.language === 'de' ? AuslagerungImages : AuslagerungImagesEN
                        break
                    case F_BESTANDSINFO:
                        obj = i18n.language === 'de' ? BestandsinfoImages : BestandsinfoImagesEN
                        break
                    case F_BESTELLERFASSUNG:
                        obj = i18n.language === 'de' ? BestellerfassungImages : BestellerfassungImagesEN
                        break
                    case F_EINLAGERUNG:
                        obj = i18n.language === 'de' ? EinlagerungImages : EinlagerungImagesEN
                        break
                    case F_VERPACKUNG:
                        obj = i18n.language === 'de' ? VerpackungImages : VerpackungImagesEN
                        break
                    case F_KOMMISSIONIERUNG:
                        obj = i18n.language === 'de' ? KommissionierungImages : KommissionierungImagesEN
                        break
                    case F_UMBUCHUNG:
                        obj = i18n.language === 'de' ? UmbuchungImages : UmbuchungImagesEN
                        break
                    case F_UMLAGERUNG:
                        obj = i18n.language === 'de' ? UmlagerungImages : UmlagerungImagesEN
                        break
                    case F_VERLADUNG:
                        obj = i18n.language === 'de' ? VerladungImages : VerladungImagesEN
                        break
                    case F_WARENAUSGANG_EXTERN:
                        obj = i18n.language === 'de' ? WarenausgangExternImages : WarenausgangExternImagesEN
                        break
                    case F_WARENAUSGANG_INTERN:
                        obj = i18n.language === 'de' ? WarenausgangInternImages : WarenausgangInternImagesEN
                        break
                    case F_WARENEINGANG:
                        obj = i18n.language === 'de' ? WareneingangImages : WareneingangImagesEN
                        break
                    case F_INVENTUR:
                        obj = i18n.language === 'de' ? InventurImages : InventurImagesEN
                        break
                    case F_KANBAN:
                        obj = i18n.language === 'de' ? KanbanImages : KanbanImagesEN
                        break
                    case F_PRODUKTIONSVERSORGUNG:
                        obj = i18n.language === 'de' ? ProduktionsversorgungImages : ProduktionsversorgungImagesEN
                        break
                    case F_QUALITAETSKONTROLLE:
                        obj = i18n.language === 'de' ? QualitaetskontrolleImages : QualitaetskontrolleImagesEN
                        break
                    case F_RUECKMELDUNG_PRODUKTION:
                        obj = i18n.language === 'de' ? RueckmeldungproduktionImages : RueckmeldungproduktionImagesEN
                        break
                    default:
                        break
                }

                setInspectedFunctionImage(obj)

                setInspectedFunction(newFunctionData)
                setSearchableInspectedFunction(newFunctionData)

                if (newFunctionData.data.length > 0) {
                    setActiveData(newFunctionData.data[0])
                    handleChangeActiveOption(newFunctionData.data[0])
                }

                setIsFunctionLoading(false)
                // window.scrollTo(0, 0)
            }
        }

        fetchData()

        return () => {
            isCancelled = true
        }
    }, [inspectedFunctionId, i18n.language])

    useEffect(() => {
        if (inspectedFunctionId === F_SONDERFUNKTIONEN_ERWEITERUNGEN)
            fetchSpecialFunctionsData();
    }, []);

    useEffect(() => {
        if (inspectedFunctionId === F_SONDERFUNKTIONEN_ERWEITERUNGEN) {
            setInspectedFunction(specialFunctions)
            setSearchableInspectedFunction(specialFunctions)
            if (specialFunctions.data.length > 0) {
                setActiveData(specialFunctions.data[specialFunctions.data.length - 1])
                handleChangeActiveOption(specialFunctions.data[specialFunctions.data.length - 1])
            }
        }
    }, [specialFunctions])

    const fetchSpecialFunctionsData = () => {
        AppService.getSpecialFunctions(userInfo.key)
            .then(response => {
                setSpecialFunctions(response.data);
            })
            .catch(err =>
                setSpecialFunctions(FUNCTION_SONDERFUNKTIONEN_ERWEITERUNGEN)
            );
    }

    const handleCarouselNavOnClick = index => () => setAppPreviewNavIndex(index)

    const handleLeftArrowOnClick = () => {
        if (appPreviewNavIndex > 0) {
            setAppPreviewNavIndex(appPreviewNavIndex - 1)
        }
    }

    const handleRightArrowOnClick = () => {
        if (appPreviewNavIndex !== inspectedFunctionImage.filter(inspectedImage => inspectedImage.id === activeData.dataType[activeData.activeIndex].systemID)[0].images.length - 1) {
            setAppPreviewNavIndex(appPreviewNavIndex + 1)
        }
    }

    const BREADCRUMB_STACK = [
        {
            icon: <ProcessOverview />,
            label: t("functionDetails.scenarioOverview"),
            typeOf: "",
            onClick: () => {
                history.push(SCENARIO_PATH)
            }
        },
        {
            icon: getFunctionIconFromId(inspectedFunctionId),
            label: inspectedFunction ? inspectedFunction.name : '',
            typeOf: inspectedFunction ? getTypeOfFunction(inspectedFunction.typeOf, t) : '',
            onClick: () => { }
        }
    ]

    let interval;
    let lastScrollTop = 0

    const handleChangeProcessSearch = e => {
        setSearch(e.target.value)

        let searchKey = e.target.value;
        let filter = ""

        const newSearchableInspectedFunction = { ...inspectedFunction }

        let filteredInspectedFunctionData = [];

        if (searchKey !== "") {
            filteredInspectedFunctionData = newSearchableInspectedFunction.data.filter(inspectedFunc => {
                let temp = ""

                inspectedFunc.dataType.forEach(func => {
                    func.info.forEach(info => {
                        // Search by title
                        temp += ` ${t(`processFunction.${inspectedFunction.systemID}.${func.systemID}.title`).toLowerCase()}`

                        // Search by description
                        temp += ` ${t(`processFunction.${inspectedFunction.systemID}.${func.systemID}.info.${info}`).toLowerCase()}`
                    })
                })

                // const lc = inspectedFunc.name.toLowerCase();
                filter = searchKey.toLowerCase();
                return temp.includes(filter)
            })

            newSearchableInspectedFunction.data = filteredInspectedFunctionData
        }
        else if (searchKey === "") {
            newSearchableInspectedFunction.data = inspectedFunction.data
        }

        setSearchableInspectedFunction(newSearchableInspectedFunction)
        window.scrollTo(0, 0)
    }

    const handleOnSearchClear = () => {
        setSearch("")

        const newSearchableInspectedFunction = { ...inspectedFunction }
        newSearchableInspectedFunction.data = inspectedFunction.data

        setSearchableInspectedFunction(newSearchableInspectedFunction)
    }

    const timeoutCallback = useCallback(event => {
        setIsScrolling(false)
    }, [])

    const viewPortCallback = () => {
        if (inspectedFunction) {
            inspectedFunction.data.forEach(data => {
                handleChangeActiveOption(data)
            })
        }
    }

    const handleDetectViewport = useCallback(event => {
        viewPortCallback()
        window.clearTimeout(interval);
        interval = setTimeout(timeoutCallback, 66)

        let st = window.scrollY || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
            // setIsDownscroll(true)
        } else {
            // setIsDownscroll(false)
        }
        lastScrollTop = st <= 0 ? 0 : st;
    }, [inspectedFunction]);

    useEffect(() => {
        window.addEventListener('scroll', handleDetectViewport, false)
        return () => {
            window.removeEventListener('scroll', handleDetectViewport, false)
        }
    }, [handleDetectViewport]);

    const handleChangeActiveOption = useCallback(
        (data) => {
            let element = document.getElementById(data.name);
            if (element) {
                const bounding = element.getBoundingClientRect();
                if (
                    bounding.top >= 0 &&
                    bounding.top <= 300
                    // &&
                    // bounding.left >= 0 &&
                    // bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                    // bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                ) {
                    if (inspectedFunction) {
                        setActiveOption(data.name);
                        setActiveData(data);
                        let scrollIndex = 0;
                        // inspectedFunction.data.forEach((eachElement, index) => {
                        //     if (eachElement.name === data.name) scrollIndex = index;
                        // });

                        const sectionHash = `#menu%${data.name}`;
                        window.location.hash = sectionHash;
                        // setIsScrolling(true)
                    }
                }
            }
        },
        [inspectedFunction]
    );

    const handleRemoveFromCollection = data => () => {
        // console.log("HANDLE REMOVE FROM COLLECTION")
        // console.log(data)

        const newCollection = [...collection]
        const newEditorStateList = [...editorStateList]

        newCollection.forEach((c, i) => {
            if (c.name === inspectedFunction.name) {
                c.processes.forEach((p, j) => {
                    if (p.name === data.name) {
                        if (JSON.stringify(p.type) === JSON.stringify(data.dataType[data.activeIndex].type)) {
                            c.processes.splice(j, 1)
                        }

                        if (c.processes.length === 0) {
                            newCollection.splice(i, 1)
                        }
                    }
                })
            }
        })

        newEditorStateList.forEach((editor, index) => {
            if (editor.function_id === inspectedFunction.systemID && editor.sub_function_id === data.dataType[data.activeIndex].systemID) {
                newEditorStateList.splice(index, 1)
            }
        })

        AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(newCollection)).then(res => {
            setCollection(convertNotesToEditorState(newCollection))
            setEditorStateList(newEditorStateList)
        }).catch(err => {
            handleShowErrorSnackbar(err);
        });
    }

    const handleAddToCollection = data => () => {
        // console.log("HANDLE ADD TO COLLECTION")
        const newCollection = [...collection]

        let isFunctionAlreadyExists = false

        newCollection.forEach(c => {
            if (c.name === inspectedFunction.name) {
                isFunctionAlreadyExists = true

                if (c.processes.filter(c => c.name === data.name && JSON.stringify(c.type) === JSON.stringify(data.dataType[data.activeIndex].type)).length === 0) {
                    c.processes.push(new CartProcess(data.dataType[data.activeIndex].systemID, data.name, data.dataType[data.activeIndex].type))
                }
                else {
                    console.log("Already added to collection")
                }
            }
        })

        if (!isFunctionAlreadyExists) {
            newCollection.push(new Cart(
                inspectedFunction.systemID,
                inspectedFunction.name,
                inspectedFunction.icon,
                inspectedFunction.typeOf,
                [new CartProcess(data.dataType[data.activeIndex].systemID, data.name, data.dataType[data.activeIndex].type)]
            ))
        }

        AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(newCollection)).then(res => {
            setCollection(convertNotesToEditorState(newCollection))
        }).catch(err => {
            handleShowErrorSnackbar(err);
        });
    }

    return (inspectedFunction && searchableInspectedFunction) && (
        <RootWrapperContainer>
            <div onScroll={handleDetectViewport} style={{ width: '100%' }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div id='breadcrumb-container'
                            style={{
                                width: '100%',
                                position: 'fixed',
                                top: 64,
                                left: 0,
                                paddingTop: 16,
                                paddingBottom: 8,
                                background: Color.neutral[0],
                                zIndex: 1200,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <PaddedContainer>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Breadcrumb stacks={BREADCRUMB_STACK} />
                                    <div>
                                        <FunctionTypeChips type={inspectedFunction.type} />
                                    </div>
                                </div>
                            </PaddedContainer>
                        </div>
                    </Grid>
                </Grid>

                <PaddedContainer>
                    <Grid container spacing={0}>
                        <Grid item md={3}>
                            <Sidebar
                                setActiveOption={setActiveOption}
                                setActiveData={setActiveData}
                                originalMenus={inspectedFunction.data}
                                menus={searchableInspectedFunction.data}
                                search={search}
                                onSearch={handleChangeProcessSearch}
                                onClear={handleOnSearchClear}
                                activeOption={activeOption}
                                activeData={activeData}
                                isScrolling={isScrolling}
                                setIsScrolling={setIsScrolling}
                                setAppPreviewNavIndex={setAppPreviewNavIndex}
                                collection={collection}
                                handleOpenSpecialFunctionDialog={handleOpenSpecialFunctionDialog}
                            />
                        </Grid>
                        {(searchableInspectedFunction.data.length > 0) && (
                            <>
                                <Grid item md={6} style={{ position: "relative" }}>
                                    {searchableInspectedFunction.data.map((func, index) => {
                                        return (
                                            <FunctionLists
                                                key={`function${index}`}
                                                func={func}
                                                search={search}
                                                inspectedFunction={inspectedFunction}
                                                setInspectedFunction={setInspectedFunction}
                                                inspectedFunctionImage={inspectedFunctionImage}
                                                appPreviewNavIndex={appPreviewNavIndex}
                                                setAppPreviewNavIndex={setAppPreviewNavIndex}
                                                handleOpenSpecialFunctionDialog={handleOpenSpecialFunctionDialog}
                                                setIsEditingSpecialFunction={setIsEditingSpecialFunction}
                                                setInspectedSpecialFunctionData={setInspectedSpecialFunctionData}
                                                handleAddToCollection={handleAddToCollection}
                                                handleRemoveFromCollection={handleRemoveFromCollection}
                                                addCollectionButton={AddCollectionButton}
                                                removeCollectionButton={RemoveCollectionButton}
                                                handleOpenDeleteDialog={handleOpenDeleteDialog}
                                                handleAddEditorStateList={handleAddEditorStateList}
                                                editorStateList={editorStateList}
                                                userInfo={userInfo}
                                                inspectedFunctionId={inspectedFunctionId}
                                                activeOption={activeOption}
                                            />
                                            // <></>
                                        )
                                    })}
                                    <div style={{ height: "50vh" }} />
                                </Grid>
                                <Grid item md={3}>
                                    {(activeData && inspectedFunctionImage) && (
                                        <div>
                                            {(activeData && activeData.dataType[activeData.activeIndex].isCollectible && inspectedFunctionId !== F_SONDERFUNKTIONEN_ERWEITERUNGEN) && (
                                                <>
                                                    {isProcessTypeAlreadyAddedToCollection(collection, activeData) ? (
                                                        <div className={classes.addedToCollection}>
                                                            <RemoveCollectionButton activeData={activeData} handleRemoveFromCollection={handleRemoveFromCollection} />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={classes.addToCollection}
                                                        >
                                                            <AddCollectionButton activeData={activeData} handleAddToCollection={handleAddToCollection} />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            <AppPreview
                                                inspectedFunctionImage={inspectedFunctionImage}
                                                activeData={activeData}
                                                appPreviewNavIndex={appPreviewNavIndex}
                                                setAppPreviewNavIndex={setAppPreviewNavIndex}
                                                handleCarouselNavOnClick={handleCarouselNavOnClick}
                                                handleLeftArrowOnClick={handleLeftArrowOnClick}
                                                handleRightArrowOnClick={handleRightArrowOnClick}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {(searchableInspectedFunction.data.length === 0 || inspectedFunction.data.length === 0) && (
                        <div
                            className={classes.noDataLayout}
                        >
                            {inspectedFunction.data.length === 0 ? (<Copy style={{ width: 134, height: 134, color: ColorSAP.primary[20] }} />) : (<Search style={{ width: 134, height: 134, color: ColorSAP.primary[20] }} />)}
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={36}
                                color={ColorSAP.primary[100]}
                                style={{ marginBottom: 30 }}
                            >
                                {inspectedFunction.data.length === 0 ? `${t("functionDetails.noCustomAvailable")}` : `${t("common.noSearchResult")}`}
                            </Typography>
                            {(inspectedFunction.data.length === 0 && inspectedFunctionId === 'F_SONDERFUNKTIONEN_ERWEITERUNGEN') && (
                                <Button
                                    type="primary"
                                    theme="dark"
                                    size="small"
                                    onClick={handleOpenSpecialFunctionDialog}
                                >
                                    {t('functionDetails.newFunction')}
                                </Button>
                            )}
                        </div>
                    )}
                </PaddedContainer>

                <SpecialFunctionDialog
                    open={specialFunctionDialogOpen}
                    onClose={handleCloseSpecialFunctionDialog}
                    isEditing={isEditingSpecialFunction}
                    func={inspectedSpecialFunctionData}
                />

                <DeleteDialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    onDelete={handleDeleteSpecialFunction}
                    title={"Function"}
                    dataName={inspectedSpecialFunctionData && inspectedSpecialFunctionData.name}
                />
            </div>
        </RootWrapperContainer>
    )
};

export default ConfigurationContainer;