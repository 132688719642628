import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';

import { useStyles } from './CollectionCartStyles';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Components
import {
    Check,
    Color,
    ColorSAP,
    Copy,
    CopyToClipboard,
    FONT_FAMILY,
    Form,
    Typography,
} from '@commsult/ontego-ui';

import Grid from '@material-ui/core/Grid';

import { ReactComponent as ThankyouIcon } from './thankyou_icon.svg';

import { convertNotesToEditorState, convertNotesToJSON, convertUpAndRunningNotesToEditorState, convertUpAndRunningNotesToJSON, formatCollectionForEmail } from '../../Utils/Utils';

import AppService from '../../AppService';
import { getFunctionIconFromId } from '../../Utils/FunctionUtils';
import CollectionService from './CollectionService';
import UpAndRunningPhaseDeleteDialog from './UpAndRunningPhaseDeleteDialog';
import UpAndRunningPhaseDialog from './UpAndRunningPhaseDialog';

import { useTranslation } from 'react-i18next';
import Button from '../../ReusableComponents/Button/Button';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import FunctionCardCollection from '../../ReusableComponents/FunctionCardCollection/FunctionCardCollection';
import UpAndRunning from '../../ReusableComponents/UpAndRunning/UpAndRunning';

import { EditorState } from 'draft-js';
import NoData from '../../Icons/NoData';
import useScreenSize from '../../Utils/useScreenSize';

const getSteps = () => {
    return [`Meine Funktionsübersicht`, `Angebot / Zusammenfassung`]
}

const NoFunction = () => {
    const { t } = useTranslation();
    const classes = useStyles()
    const screenSize = useScreenSize();

    return (
        <div
            className={classes.dialogSize}
            style={{
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: screenSize.height > 910 ? `calc(${screenSize.height}px - 278px)` : `calc(${screenSize.height}px - 250px)`
            }}
        >
            <div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Copy style={{ width: 104, height: 104, color: ColorSAP.primary[70] }} />
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={24}
                        color={ColorSAP.neutral[100]}
                        style={{ marginTop: 8, marginBottom: 8 }}
                    >
                        {t("dialogs.noFeaturesInCollection")}
                    </Typography>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                    >
                        {t("dialogs.noFeaturesInCollectionExp")}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

const CollectionCart = props => {
    const classes = useStyles(props)
    const { open, onClose } = props

    const { userInfo, collection, setCollection, upAndRunningCollection, setUpAndRunningCollection, handleShowSnackbar, handleShowErrorSnackbar } = useContext(AppContext)

    /** EMAIL */
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [email, setEmail] = useState("")

    const [nameError, setNameError] = useState(false)
    const [companyError, setCompanyError] = useState(false)
    const [emailError, setEmailError] = useState(false)

    const [hasSuccessfullySendEmail, setHasSuccessfullySendEmail] = useState(false)

    const [activeStep, setActiveStep] = useState(0)

    const [upAndRunningDialogOpen, setUpAndRunningDialogOpen] = useState(false)
    const [upAndRunningDialogTitle, setUpAndRunningDialogTitle] = useState("")

    const [upAndRunningDeleteDialogOpen, setUpAndRunningDeleteDialogOpen] = useState(false)

    const [inspectedUpAndRunning, setInspectedUpAndRunning] = useState(null)

    const screenSize = useScreenSize()

    const handleUpAndRunningDialogOpen = (title, data) => {
        setUpAndRunningDialogTitle(title)
        setInspectedUpAndRunning(data)
        setUpAndRunningDialogOpen(true)
    }

    const handleUpAndRunningDialogClose = () => setUpAndRunningDialogOpen(false)

    const handleUpAndRunningDeleteDialogOpen = (data) => {
        setInspectedUpAndRunning(data)
        setUpAndRunningDeleteDialogOpen(true)
    }

    const handleUpAndRunningDeleteDialogClose = () => setUpAndRunningDeleteDialogOpen(false)

    const { t } = useTranslation();

    useEffect(() => {
        const newCollection = [...collection]

        newCollection.forEach(c => {
            c.isEditMode = false

            // console.log(c)

            // if (c.notes) {
            //     c.notes = EditorState.createWithContent(convertFromRaw(JSON.parse(c.notes)))
            // }

            c.processes.forEach(p => {
                p.isEditMode = false
            })
        })

        newCollection.forEach(collection => {
            let temp = false
            Object.keys(upAndRunningCollection).forEach(key => {
                if (collection.upAndRunningId === key) {
                    temp = true
                }
            })

            if (!temp) {
                collection.upAndRunningId = ""
            }
        })

        setCollection(newCollection)
        setActiveStep(0)
        setHasSuccessfullySendEmail(false)
    }, [open])

    const handleClose = () => {
        setHasSuccessfullySendEmail(false)
        onClose()
    }

    const handleChangeName = e => {
        setName(e.target.value)
        setNameError(false)
    }

    const handleChangeCompany = e => {
        setCompany(e.target.value)
        setCompanyError(false)
    }

    const handleChangeEmail = e => {
        setEmail(e.target.value)
        setEmailError(false)
    }

    const handleSendEmail = () => {
        let hasError = false

        if (name === "") {
            setNameError(true)
            hasError = true
        }

        if (company === "") {
            setCompanyError(true)
            hasError = true
        }

        if (email === "") {
            setEmailError(true)
            hasError = true
        }

        if (!hasError) {
            /** ONTEGO E-MAIL */
            const emailData = {
                name: name,
                company: company,
                email: email,
                collection: formatCollectionForEmail(collection, upAndRunningCollection)
            }

            CollectionService.emailCollectionData(emailData)
                .then(res => {
                    setHasSuccessfullySendEmail(true)
                })
                .catch(err => {
                    console.log(err);
                    handleShowErrorSnackbar(err);
                })
        }
    }

    const handleRemoveFunction = func => {
        const newCollection = [...collection]

        newCollection.forEach((c, i) => {
            if (c.name === func.name) {
                newCollection.splice(i, 1)
            }
        })

        handleShowSnackbar({
            message: t("dialogs.processRemoved"),
            autoHideDuration: 5000,
            icon: <CopyToClipboard />
        });

        AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(newCollection)).then(res => {
            setCollection(convertNotesToEditorState(newCollection));
        }).catch(error => handleShowErrorSnackbar(error));
    }

    const handleRemoveProcess = (func, process) => {
        const newCollection = [...collection]

        newCollection.forEach((c, i) => {
            if (c.systemID === func.systemID) {
                c.processes.forEach((p, j) => {
                    if (p.systemID === process.systemID) {
                        c.processes.splice(j, 1)
                    }
                })
            }
        })

        AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(newCollection)).then(res => {
            setCollection(convertNotesToEditorState(newCollection));
        }).catch(err => {
            handleShowErrorSnackbar(err);
        });
    }

    const handleToggleEditMode = (data, type) => () => {
        // console.group("TOGGLE EDIT NOTE...")
        // console.log(data)
        // console.log(type)
        // console.groupEnd()

        let arr = null

        switch (type) {
            case 'FUNCTION':
                arr = [...collection]
                arr.forEach(func => {
                    if (func.systemID === data.systemID) {
                        func.isEditMode = !func.isEditMode
                    }
                })
                setCollection(arr)
                break
            case 'PROCESS':
                arr = [...collection]
                arr.forEach(func => {
                    func.processes.forEach(process => {
                        if (process.systemID === data.systemID) {
                            process.isEditMode = !process.isEditMode
                        }
                    })
                })
                setCollection(arr)
                break
            case 'UP_AND_RUNNING':
                arr = { ...upAndRunningCollection }
                Object.values(arr).forEach(upAndRunning => {
                    if (upAndRunning.id === data.id) {
                        upAndRunning.isEditMode = !upAndRunning.isEditMode
                    }
                })
                setUpAndRunningCollection(arr)
                break
        }
    }

    const handleSaveNote = (data, type, value) => {
        console.log("HANDLE SAVE NOTE")
        // console.log(data)
        // console.log(type)
        // console.log(value)

        let arr = null

        switch (type) {
            case 'FUNCTION':
                arr = [...collection]
                arr.forEach(func => {
                    if (func.systemID === data.systemID) {
                        func.notes = value
                        func.isEditMode = false
                    }
                })
                AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(arr)).then(res => {
                    setCollection(convertNotesToEditorState(arr))
                }).catch(error => handleShowErrorSnackbar(error));
                break
            case 'PROCESS':
                arr = [...collection]
                arr.forEach(func => {
                    func.processes.forEach(process => {
                        if (process.systemID === data.systemID) {
                            process.notes = value
                            process.isEditMode = false
                        }
                    })
                })
                AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(arr)).then(res => {
                    setCollection(convertNotesToEditorState(arr))
                }).catch(error => handleShowErrorSnackbar(error));
                break
            case 'UP_AND_RUNNING':
                arr = { ...upAndRunningCollection }
                Object.values(arr).forEach(upAndRunning => {
                    if (upAndRunning.id === data.id) {
                        upAndRunning.notes = value
                        upAndRunning.isEditMode = false
                    }
                })
                AppService.updateUpAndRunningCollection(userInfo.key, convertUpAndRunningNotesToJSON(arr)).then(res => {
                    setUpAndRunningCollection(convertUpAndRunningNotesToEditorState(arr))
                }).catch(error => handleShowErrorSnackbar(error));
                break
        }
    }

    const handleDeleteNote = (data, type) => {
        // console.log("HANDLE DELETE NOTE")
        // console.log(data)
        // console.log(type)

        let arr = null

        switch (type) {
            case 'FUNCTION':
                arr = [...collection]
                arr.forEach(func => {
                    if (func.systemID === data.systemID) {
                        func.notes = EditorState.createEmpty()
                    }
                })
                AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(arr)).then(res => {
                    setCollection(convertNotesToEditorState(arr))
                }).catch(error => handleShowErrorSnackbar(error));
                break
            case 'PROCESS':
                arr = [...collection]
                arr.forEach(func => {
                    func.processes.forEach(process => {
                        if (process.systemID === data.systemID) {
                            process.notes = EditorState.createEmpty()
                        }
                    })
                })
                AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(arr)).then(res => {
                    setCollection(convertNotesToEditorState(arr))
                }).catch(error => handleShowErrorSnackbar(error));
                break
            case 'UP_AND_RUNNING':
                arr = { ...upAndRunningCollection }
                Object.values(arr).forEach(upAndRunning => {
                    if (upAndRunning.id === data.id) {
                        upAndRunning.notes = EditorState.createEmpty()
                    }
                })
                AppService.updateUpAndRunningCollection(userInfo.key, convertUpAndRunningNotesToJSON(arr)).then(res => {
                    setUpAndRunningCollection(convertUpAndRunningNotesToEditorState(arr))
                }).catch(error => handleShowErrorSnackbar(error));
                break
        }
    }

    const handleCopyNote = () => {
        handleShowSnackbar({
            message: t("dialogs.noteCopied"),
            autoHideDuration: 1000,
            icon: <CopyToClipboard />,
            showButton: false
        })
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const getStepContent = (t, activeStep) => {
        switch (activeStep) {
            default:
                return (
                    <Grid item md={12}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <div style={{ display: 'flex', position: 'relative' }}>
                                <div className={classes.functionListCardsContainer}>
                                    <div className={classes.functionListContainerTitle}>
                                        <Typography
                                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                            size={20}
                                            color={ColorSAP.neutral[100]}
                                            style={{ paddingBottom: 8 }}
                                        >
                                            {t('collection.selectedFunction')}
                                        </Typography>
                                        <Typography
                                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                            size={14}
                                            color={ColorSAP.neutral[100]}
                                        >
                                            {t('collection.selectedFunctionDesc')}
                                        </Typography>
                                    </div>
                                    <div className={classes.functionListContainer}>
                                        <div
                                            className={classes.functionList}
                                            style={{
                                                height: `calc(${screenSize.height}px - 300px)`,
                                            }}
                                        >
                                            <Droppable droppableId="function-collection-droppable">
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        className={classes.functionListItems}
                                                    >
                                                        {collection.filter(collection => collection.upAndRunningId === "").length > 0 ?
                                                            (collection.filter(collection => collection.upAndRunningId === "").map((functionData, index) => (
                                                                <Draggable
                                                                    key={functionData.systemID}
                                                                    draggableId={functionData.systemID}
                                                                    index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            key={`${functionData.systemID}-function-card-collection-key-${index}`}
                                                                            id={`${functionData.systemID}-draggable-card`}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={getStyle(snapshot, provided.draggableProps.style)}
                                                                        >
                                                                            <div style={{ marginTop: index > 0 ? 12 : 'auto', marginRight: 18 }}>
                                                                                <FunctionCardCollection
                                                                                    id={`${functionData.name}-${functionData.systemID}`}
                                                                                    func={functionData}
                                                                                    icon={getFunctionIconFromId(functionData.systemID)}
                                                                                    isDragging={snapshot.isDragging}
                                                                                    onEditNote={handleToggleEditMode}
                                                                                    onSaveNote={handleSaveNote}
                                                                                    onDeleteNote={handleDeleteNote}
                                                                                    onCopyNote={handleCopyNote}
                                                                                    handleRemoveFunction={handleRemoveFunction}
                                                                                    handleRemoveProcess={handleRemoveProcess}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))) :
                                                            (
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        textAlign: 'center',
                                                                        flexDirection: 'column'
                                                                    }}
                                                                >
                                                                    <Check
                                                                        style={{
                                                                            width: 64,
                                                                            height: 64,
                                                                            color: ColorSAP.neutral[40],
                                                                            marginTop: 40,
                                                                            marginBottom: 8
                                                                        }}
                                                                    />
                                                                    <Typography
                                                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                                                        size={18}
                                                                        color={ColorSAP.neutral[40]}
                                                                    >
                                                                        {t('Alle Funktionen hinzugefügt')}
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={Object.keys(upAndRunningCollection).length === 0 ? classes.upAndRunningCardsContainerEmpty : classes.upAndRunningCardsContainer}
                                    style={{
                                        height: `calc(${screenSize.height}px - 248px)`,
                                        position: 'relative',
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                >
                                    {/* sticky header */}
                                    {Object.keys(upAndRunningCollection).length !== 0 && (
                                        <div
                                            style={{
                                                height: 10,
                                                background: "#F0F1FF", position: 'sticky', left: '0px', top: '0px', padding: "32px 24px 32px 24px", borderRadius: 13, zIndex: '50', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography
                                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                                size={20}
                                                color={ColorSAP.neutral[100]}
                                                style={{ marginRight: 40 }}
                                            >
                                                {`Phase`}
                                            </Typography>
                                            <Button
                                                type="primary"
                                                size="small"
                                                theme="dark"
                                                onClick={onCreateUpAndRunning}
                                            >
                                                {t('collection.createPhase')}
                                            </Button>
                                        </div>
                                    )}


                                    <div className={Object.keys(upAndRunningCollection).length === 0 ? classes.upAndRunningListContainerEmpty : classes.upAndRunningListContainer}>
                                        {Object.keys(upAndRunningCollection).map((upAndRunningId, index) => {
                                            const upAndRunningObj = upAndRunningCollection[upAndRunningId]
                                            const upAndRunningFunctions = collection.filter(collection => collection.upAndRunningId === upAndRunningId)
                                            const hasChild = upAndRunningFunctions.length > 0

                                            return (
                                                <Droppable droppableId={upAndRunningId}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            key={`${upAndRunningId}-up-and-running-key-${index}`}
                                                            ref={provided.innerRef}
                                                            style={getDroppableStyle(upAndRunningId, snapshot, index)}
                                                        >
                                                            <UpAndRunning
                                                                data={upAndRunningObj}
                                                                isDragging={snapshot.isDraggingOver}
                                                                onEditNote={handleToggleEditMode}
                                                                onSaveNote={handleSaveNote}
                                                                onDeleteNote={handleDeleteNote}
                                                                onCopyNote={handleCopyNote}
                                                                hasChild={hasChild}
                                                                onTimelineChange={onTimelineChange(upAndRunningId)}
                                                                onEdit={onEditUpAndRunning(upAndRunningObj)}
                                                                onDelete={onDeleteUpAndRunning(upAndRunningObj)}
                                                            >
                                                                <div
                                                                    key={`${upAndRunningId}-functions-list-key-${index}`}
                                                                    id={`${upAndRunningId}-functions-list`}
                                                                    className={classes.upAndRunningList}
                                                                >
                                                                    {upAndRunningFunctions.map((functionData, index) => (
                                                                        <Draggable
                                                                            key={functionData.systemID}
                                                                            draggableId={functionData.systemID}
                                                                            index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    key={`${functionData.systemID}-up-and-running`}
                                                                                    id={`${functionData.systemID}-draggable-card`}
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getStyle(snapshot, provided.draggableProps.style)}
                                                                                    className={classes.upAndRunningListItems}
                                                                                >
                                                                                    <div style={{ marginTop: index > 0 ? 12 : 'auto' }}>
                                                                                        <FunctionCardCollection
                                                                                            id={`${functionData.name}-${functionData.systemID}`}
                                                                                            func={functionData}
                                                                                            icon={getFunctionIconFromId(functionData.systemID)}
                                                                                            isDragging={snapshot.isDragging}
                                                                                            onEditNote={handleToggleEditMode}
                                                                                            onSaveNote={handleSaveNote}
                                                                                            onDeleteNote={handleDeleteNote}
                                                                                            onCopyNote={handleCopyNote}
                                                                                            handleRemoveFunction={handleRemoveFunction}
                                                                                            handleRemoveProcess={handleRemoveProcess}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                </div>
                                                            </UpAndRunning>
                                                            <div style={{ maxHeight: "1px", maxWidth: '1px' }}>{provided.placeholder}</div>
                                                            {/* {provided.placeholder} */}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            )
                                        })}
                                        <div
                                            style={{
                                                textAlign: Object.keys(upAndRunningCollection).length === 0 && '-webkit-center',
                                                position: Object.keys(upAndRunningCollection).length > 0 && 'absolute',
                                                left: Object.keys(upAndRunningCollection).length > 0 && 0,
                                                top: Object.keys(upAndRunningCollection).length > 0 && 0,
                                                display: Object.keys(upAndRunningCollection).length > 0 && 'flex',
                                                alignItems: Object.keys(upAndRunningCollection).length > 0 && 'center',
                                                justifyContent: Object.keys(upAndRunningCollection).length > 0 && 'space-between',
                                                width: Object.keys(upAndRunningCollection).length > 0 && '100%',
                                            }}
                                        >
                                            {Object.keys(upAndRunningCollection).length === 0 ? (
                                                <>
                                                    <NoData style={{ width: 140, height: 140 }} />
                                                    <Typography
                                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                                        size={20}
                                                        color={ColorSAP.neutral[100]}
                                                    >
                                                        {`Empty Phase`}
                                                    </Typography>
                                                    <Typography
                                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                                        size={14}
                                                        color={ColorSAP.neutral[100]}
                                                        style={{ marginTop: 8, marginBottom: 16 }}
                                                    >
                                                        {`Add a new phase by click the button below`}
                                                    </Typography>
                                                    <Button
                                                        type="primary"
                                                        size="small"
                                                        theme="dark"
                                                        onClick={onCreateUpAndRunning}
                                                    >
                                                        {t('collection.createPhase')}
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                </>
                                                // <div
                                                //     style={{ position: 'sticky', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                                // >
                                                //     <Typography
                                                //         fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                                //         size={20}
                                                //         color={ColorSAP.neutral[100]}
                                                //         style={{ marginRight: 40 }}
                                                //     >
                                                //         {`Phase`}
                                                //     </Typography>
                                                //     <Button
                                                //         type="primary"
                                                //         size="small"
                                                //         theme="dark"
                                                //         onClick={onCreateUpAndRunning}
                                                //     >
                                                //         {t('collection.createPhase')}
                                                //     </Button>
                                                // </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DragDropContext>
                    </Grid>
                )
            case 1:
                return (
                    <Grid item md={12}>
                        <Grid container spacing={0}>
                            <Grid item container md={5} spacing={0}>
                                <Grid item md={12}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                        size={24}
                                        color={Color.neutral[600]}
                                        style={{ letterSpacing: -0.8 }}
                                    >
                                        {`Angebot stellen`}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Form
                                        placeholder="Angebot Nr."
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Form
                                        placeholder="Zugriffs-PIN"
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Form
                                        placeholder="Datum"
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Form
                                        placeholder="Binderfrist"
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Button>
                                        {`Angebot erstellen`}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item container md={2} spacing={0} />
                            <Grid item container md={5} spacing={0}>
                                <Grid item md={12}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                                        size={24}
                                        color={Color.neutral[600]}
                                        style={{ letterSpacing: -0.8 }}
                                    >
                                        {`Zusammenfassung zusenden`}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Form
                                        placeholder="Name"
                                        value={name}
                                        onChange={handleChangeName}
                                        error={nameError}
                                        errorMessage={nameError && t(`manage.fieldRequired`)}
                                        required
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Form
                                        placeholder="Unternehmen"
                                        value={company}
                                        onChange={handleChangeCompany}
                                        error={companyError}
                                        errorMessage={companyError && t(`manage.fieldRequired`)}
                                        required
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Form
                                        placeholder="Email"
                                        value={email}
                                        onChange={handleChangeEmail}
                                        error={emailError}
                                        errorMessage={emailError && t(`manage.fieldRequired`)}
                                        required
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Form style={{ visibility: 'hidden' }} />
                                </Grid>
                                <Grid item md={12}>
                                    <Button
                                        alignIcon="right"
                                        onClick={handleSendEmail}
                                    >
                                        {`Email senden`}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
        }
    }

    const onDragEnd = result => {
        const { destination, draggableId } = result

        const newCollection = [...collection]

        // dropped outside the list
        if (!destination) {
            return
        }
        else if (destination.droppableId === 'function-collection-droppable') {
            // console.log("FUNCTION DROPPABLE")
            newCollection.filter(collection => collection.systemID === draggableId)[0].upAndRunningId = ""
        }
        else {
            // console.log("UP AND RUNNING DROPPABLE")
            newCollection.filter(collection => collection.systemID === draggableId)[0].upAndRunningId = destination.droppableId
        }

        AppService.updateFunctionCollection(userInfo.key, convertNotesToJSON(newCollection)).then(res => {
            setCollection(convertNotesToEditorState(newCollection))
        }).catch(error => {
            console.log(error)
            handleShowErrorSnackbar(error)
        });
    }

    const getDroppableStyle = (droppableId, snapshot, index) => {
        if (snapshot.draggingOverWith) {
            if (collection.filter(c => c.systemID === snapshot.draggingOverWith)[0].upAndRunningId !== droppableId) {
                const draggableEl = document.getElementById(`${snapshot.draggingOverWith}-draggable-card`)
                const droppableEl = document.getElementById(`${droppableId}-functions-list`)

                if (draggableEl && droppableEl) {
                    const draggableElHeight = draggableEl.getBoundingClientRect().height
                    const droppableElHeight = droppableEl.getBoundingClientRect().height

                    let temp = droppableElHeight
                    temp += draggableElHeight

                    droppableEl.style.height = `${temp - 20}px`
                }
            }
        }
        else {
            const droppableEl = document.getElementById(`${droppableId}-functions-list`)

            if (droppableEl) {
                droppableEl.style.height = 'fit-content'
            }
        }

        return {
            height: 'fit-content',
            paddingRight: 16,
            paddingBottom: 16,
            // marginTop: 100
        }
    }

    const getStyle = (snapshot, draggableStyle) => {
        if (!snapshot.isDropAnimating) {
            return draggableStyle
        }

        return {
            ...draggableStyle,
            // cannot be 0, but make it super tiny
            width: 'fit-content',
            height: 'fit-content',
            transitionDuration: `0.001s`,
        }
    }

    const onTimelineChange = id => e => {
        const newUpAndRunningCollection = { ...upAndRunningCollection }
        newUpAndRunningCollection[id].selectedTimeline = e.target.value

        AppService.updateUpAndRunningCollection(userInfo.key, convertUpAndRunningNotesToJSON(newUpAndRunningCollection)).then(res => {
            setUpAndRunningCollection(convertUpAndRunningNotesToEditorState(newUpAndRunningCollection))
        }).catch(error => handleShowErrorSnackbar(error));
    }

    const onCreateUpAndRunning = () => {
        handleUpAndRunningDialogOpen(t('collection.createPhase'), null)
    }

    const onEditUpAndRunning = data => () => {
        handleUpAndRunningDialogOpen(t('collection.editPhase'), data)
    }

    const onDeleteUpAndRunning = data => () => {
        handleUpAndRunningDeleteDialogOpen(data)
    }

    return (
        <>
            <DialogBox
                open={open}
                handleClose={handleClose}
                textTitle={t("dialogs.featureOverview")}
                maxWidth={'xl'}
                content={
                    <>
                        {hasSuccessfullySendEmail ? (
                            <div
                                style={{
                                    height: '85vh',
                                    width: '90vw',
                                    overflow: 'hidden'
                                }}
                            >
                                <div style={{ width: '100%', textAlign: 'center', marginTop: 45 }}>
                                    <ThankyouIcon style={{ width: 275, height: 275 }} />
                                </div>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <Typography
                                        fontFamily={FONT_FAMILY.ROBOTO_MEDIUM}
                                        size={36}
                                        color={Color.neutral[400]}
                                        style={{ marginTop: 32 }}
                                    >
                                        {`${t("dialogs.thankYouSendingCollection")}!`}
                                    </Typography>
                                    <Button
                                        alignIcon="right"
                                        icon={<Check />}
                                        style={{
                                            marginTop: 32
                                        }}
                                        onClick={onClose}
                                    >
                                        {`Schliessen`}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div id="dialogPopUpContainer">
                                {collection.length > 0 ? (
                                    <>
                                        <div
                                            className={classes.dialogSize}
                                        >
                                            <Grid container spacing={0} style={{ position: 'relative', padding: 24 }}>
                                                {/* <Grid item md={12} style={{ position: 'sticky', top: 0, background: '#fff', zIndex: 1000 }}>
                                                        <Stepper
                                                            steps={getSteps()}
                                                            activeStep={activeStep}
                                                        />
                                                    </Grid> */}
                                                {getStepContent(t, activeStep)}
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <NoFunction />
                                )}
                            </div>
                        )}

                    </>
                }
            />
            {
                upAndRunningDialogOpen && (
                    <UpAndRunningPhaseDialog
                        title={upAndRunningDialogTitle}
                        open={upAndRunningDialogOpen}
                        onClose={handleUpAndRunningDialogClose}
                        upAndRunningCollection={upAndRunningCollection}
                        setUpAndRunningCollection={setUpAndRunningCollection}
                        inspectedData={inspectedUpAndRunning}
                    />
                )
            }
            {
                upAndRunningDeleteDialogOpen && (
                    <UpAndRunningPhaseDeleteDialog
                        open={upAndRunningDeleteDialogOpen}
                        onClose={handleUpAndRunningDeleteDialogClose}
                        upAndRunningCollection={upAndRunningCollection}
                        setUpAndRunningCollection={setUpAndRunningCollection}
                        collection={collection}
                        setCollection={setCollection}
                        inspectedData={inspectedUpAndRunning}
                    />
                )
            }
        </>
    )
}

export default CollectionCart;