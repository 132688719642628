import React from 'react';

import { Delivery, Factory, GoodsReceipt, Inventory, InventoryStatus, OutSource, PickPack, ProductionFeedback, Puzzle, StockTransfer } from "@commsult/ontego-ui";
import { DeliveryList, GoodsIssue, Kanban, Order, Packages, Quality, Rebooking, Warehouse } from "@commsult/ontego-ui/dist/sap_icons";
import { F_AUSLAGERUNG, F_BESTANDSINFO, F_BESTANDSINFO_MOBILE_INVENTORY_SOLUTIONS, F_BESTELLERFASSUNG, F_EINLAGERUNG, F_INVENTUR, F_INVENTUR_LEITSTAND, F_INVENTUR_MOBILE_INVENTORY_SOLUTIONS, F_KANBAN, F_KOMMISSIONIERUNG, F_PRODUKTIONSVERSORGUNG, F_QUALITAETSKONTROLLE, F_QUALITAETSKONTROLLE_MANAGEMENT_MOBILE_WAREHOUSE_LOGISTICS_SOLUTIONS, F_RUECKMELDUNG_PRODUKTION, F_SONDERFUNKTIONEN_ERWEITERUNGEN, F_UMBUCHUNG, F_UMLAGERUNG, F_VERLADUNG, F_VERPACKUNG, F_VERPACKUNG_PRODUKTION, F_WARENAUSGANG_EXTERN, F_WARENAUSGANG_INTERN, F_WARENEINGANG } from "../Constants/FunctionIds";
import Monitor from '../Icons/Monitor';

export const getFunctionIconFromId = id => {
    switch (id) {
        case F_AUSLAGERUNG:
            return <OutSource />
        case F_BESTANDSINFO:
            return <InventoryStatus />
        case F_BESTELLERFASSUNG:
            return <Order />
        case F_EINLAGERUNG:
            return <Warehouse />
        case F_VERPACKUNG:
            return <Packages />
        case F_KOMMISSIONIERUNG:
            return <PickPack />
        case F_UMBUCHUNG:
            return <Rebooking />
        case F_UMLAGERUNG:
            return <StockTransfer />
        case F_VERLADUNG:
            return <DeliveryList />
        case F_WARENAUSGANG_EXTERN:
            return <Delivery />
        case F_WARENAUSGANG_INTERN:
            return <GoodsIssue />
        case F_WARENEINGANG:
            return <GoodsReceipt />
        case F_INVENTUR:
            return <Inventory />
        case F_KANBAN:
            return <Kanban />
        case F_PRODUKTIONSVERSORGUNG:
            return <Factory />
        case F_QUALITAETSKONTROLLE:
            return <Quality />
        case F_RUECKMELDUNG_PRODUKTION:
            return <ProductionFeedback />
        case F_SONDERFUNKTIONEN_ERWEITERUNGEN:
            return <Puzzle />
        case F_INVENTUR_LEITSTAND:
            return <Monitor />
        case F_VERPACKUNG_PRODUKTION:
            return <Packages />
        case F_BESTANDSINFO_MOBILE_INVENTORY_SOLUTIONS:
            return <InventoryStatus />
        case F_INVENTUR_MOBILE_INVENTORY_SOLUTIONS:
            return <Inventory />
        case F_QUALITAETSKONTROLLE_MANAGEMENT_MOBILE_WAREHOUSE_LOGISTICS_SOLUTIONS:
            return <Quality />
        default:
            break;
    }
}

export const getTypeOfFunction = (typeOf, t) => {
    switch (typeOf) {
        case 'WAREHOUSE_LOGISTICS':
            return t('scenario.warehouseLogistic')
        case 'PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE':
            return t('scenario.prodWarehouseEnchancePackage')
        case 'MOBILE_INVENTORY_SOLUTIONS':
            return t('scenario.mobileInventorySolutions')
        default:
            return ''
    }
}