// Constants
import { ColorSAP } from "@commsult/ontego-ui";
import { createStyles, makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        functionCardCollectionContainer: {
            position: 'relative',
            cursor: 'pointer',
            boxSizing: "border-box",
            padding: 16,
            borderRadius: 18,
            border: `2px solid ${ColorSAP.primary[70]}`,
            transition: "all .15s ease-in-out",
            backgroundColor: ColorSAP.neutral[0],
            "&:hover": {
                backgroundColor: ColorSAP.primary[70],
                color: `${ColorSAP.neutral[0]} !important`,
                // "& svg": {
                //     color: `${ColorSAP.neutral[0]} !important`
                // }
                "& $functionCardCollectionTextIcon": {
                    "& svg": {
                        color: ColorSAP.neutral[0],
                    }
                },
                "& $container__primary": {
                    "& svg": {
                        color: ColorSAP.neutral[0],
                    }
                }
            },
            // "&:active": {
            //     border: `2px solid ${ColorSAP.primary[80]}`,
            //     background: ColorSAP.primary[80],
            //     transition: 'none',
            // }
        },
        functionCardCollectionContainerActive: {
            position: 'relative',
            cursor: 'pointer',
            boxSizing: "border-box",
            padding: 16,
            borderRadius: 18,
            border: `2px solid ${ColorSAP.primary[70]}`,
            transition: "all .15s ease-in-out",
            backgroundColor: ColorSAP.primary[70],
            color: `${ColorSAP.neutral[0]} !important`,
            "& $functionCardCollectionTextIcon": {
                "& svg": {
                    color: ColorSAP.neutral[0],
                }
            },
            "& $container__primary": {
                "& svg": {
                    color: ColorSAP.neutral[0],
                }
            }
            // "&:active": {
            //     border: `2px solid ${ColorSAP.primary[80]}`,
            //     background: ColorSAP.primary[80],
            //     transition: 'none',
            // }
        },

        functionCardCollectionContainerDragged: {
            transform: 'rotate(2deg)',
            border: `2px solid ${ColorSAP.primary[80]}`,
            backgroundColor: ColorSAP.primary[80],
            color: `${ColorSAP.neutral[0]} !important`,
            "& svg": {
                color: `${ColorSAP.neutral[0]} !important`
            }
        },

        functionCardCollectionTextIconContainer: {
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            paddingBottom: 6,
            // paddingRight: 24,
            // wordBreak: 'break-word'
            "& svg": {
                width: 24,
                height: 24,
                color: ColorSAP.primary[100],
            }
        },
        functionCardCollectionTextIcon: {
            display: 'flex',
            alignItems: 'center',
            maxWidth: '90%',
            marginTop: 12,
            "& svg": {
                width: 32,
                height: 32,
                marginRight: 6,
                color: ColorSAP.primary[70],
            }
        },
        functionBoxCollectionNoteActionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            "& svg": {
                width: 24,
                height: 24
            }
        },
        functionCollectionNoteActionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },

        functionCollectionChipContainer: {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 10,
        }
    })
);