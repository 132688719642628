import React, { useContext, useEffect, useState } from 'react';
import { AppContext, RootWrapperContainer } from '../../App';

import { useHistory } from 'react-router-dom';

import { useStyles } from './ScenarioStyles';

import { Architect, Code, Color, ColorSAP, FONT_FAMILY, ScenarioOverview, Typography, View } from '@commsult/ontego-ui';
import { Packages, Puzzle, Warehouse } from "@commsult/ontego-ui/dist/sap_icons";

import Button from "../../ReusableComponents/Button/Button";

import Grid from '@material-ui/core/Grid';

import MobileInventorySolutionsImg from '../../assets/img/scenario/mobile_inventory_solutions.jpg';
import MobileProductionSolutionsImg from '../../assets/img/scenario/mobile_production_solutions.jpg';
import WarehouseLogisticsImg from '../../assets/img/scenario/warehouse_logistics_scenario.jpg';
// import ProductionAndWarehouseEnchancePackageImg from '../../assets/img/scenario/production_warehouse_enhance_package.jpg'

import { PaddedContainer } from '../../App';

import { FUNCTION_AUSLAGERUNG, FUNCTION_BESTANDSINFO, FUNCTION_BESTANDSINFO_MOBILE_INVENTORY_SOLUTIONS, FUNCTION_BESTELLERFASSUNG, FUNCTION_EINLAGERUNG, FUNCTION_HU_MANAGEMENT, FUNCTION_INVENTUR, FUNCTION_INVENTUR_LEITSTAND, FUNCTION_KANBAN, FUNCTION_KOMMISSIONERUNG, FUNCTION_PRODUKTIONSVERSORGUNG, FUNCTION_QUALITAETS_MANAGEMENT, FUNCTION_QUALITAETS_MANAGEMENT_MOBILE_WAREHOUSE_LOGISTICS_SOLUTIONS, FUNCTION_RUECKMELDUNG_PRODUKTION, FUNCTION_SONDERFUNKTIONEN_ERWEITERUNGEN, FUNCTION_UMBUCHUNG, FUNCTION_UMLAGERUNG, FUNCTION_VERLADUNG, FUNCTION_VERPACKUNG_PRODUKTION, FUNCTION_WARENAUSGANG_EXTERN, FUNCTION_WARENAUSGANG_INTERN, FUNCTION_WARENEINGANG } from '../../Constants/FunctionCollection';

import { FUNCTION_DETAILS_PATH, SPECIAL_FUNCTIONS_PATH } from '../../Constants/Path';
import OntegoDesignerDialog from '../../Dialogs/OntegoDesignerDialog';
import { getFunctionIconFromId } from '../../Utils/FunctionUtils';

import ApplicationFlowDesignerImage from '../../assets/img/ontegoDesigner/application_flow_designer.png';
import BackendDesignerImage from '../../assets/img/ontegoDesigner/backend_service_designer.png';
import OntegoDesignerImage from '../../assets/img/ontegoDesigner/ontego_designer.png';
import SonderfunktionenImage from '../../assets/img/ontegoDesigner/sonderfunktionen.jpg';
import UserInterfaceDesignerImage from '../../assets/img/ontegoDesigner/user_interface_designer.png';

import { usePersistedState } from '../../Utils/Utils';

import { useTranslation } from 'react-i18next';
import { F_SONDERFUNKTIONEN_ERWEITERUNGEN } from '../../Constants/FunctionIds';
import RobotArm from '../../Icons/RobotArm';
import ProcessCard from '../../ReusableComponents/ProcessCard/ProcessCard';
import OntegoChecklists from './OntegoChecklists';

const WAREHOUSE_LOGISTICS_FUNCTIONS = [
    FUNCTION_WARENEINGANG,
    FUNCTION_EINLAGERUNG,
    FUNCTION_UMLAGERUNG,
    FUNCTION_UMBUCHUNG,
    FUNCTION_AUSLAGERUNG,
    FUNCTION_KOMMISSIONERUNG,
    FUNCTION_WARENAUSGANG_INTERN,
    FUNCTION_WARENAUSGANG_EXTERN,
    FUNCTION_HU_MANAGEMENT,
    FUNCTION_BESTANDSINFO,
    FUNCTION_VERLADUNG,
    FUNCTION_BESTELLERFASSUNG,
    FUNCTION_QUALITAETS_MANAGEMENT_MOBILE_WAREHOUSE_LOGISTICS_SOLUTIONS
]

const PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE_FUNCTIONS = [
    FUNCTION_PRODUKTIONSVERSORGUNG,
    FUNCTION_KANBAN,
    FUNCTION_RUECKMELDUNG_PRODUKTION,
    FUNCTION_QUALITAETS_MANAGEMENT,
    FUNCTION_VERPACKUNG_PRODUKTION
]

const MOBILE_INVENTORY_SOLUTIONS_FUNCTIONS = [
    FUNCTION_INVENTUR,
    FUNCTION_BESTANDSINFO_MOBILE_INVENTORY_SOLUTIONS,
    FUNCTION_INVENTUR_LEITSTAND
]

const DesignerOverview = props => {
    const classes = useStyles(props)
    const { overview, handleOpenOntegoDesignerDialog } = props

    return (
        <div className={classes.designerOverviewContainer}>
            <div
                style={{
                    cursor: 'pointer',
                    marginTop: -110,
                    width: '100%'
                    // background: 'transparent',
                    // backgroundImage: `url(${overview.image})`,
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: 'contain'
                }}
                onClick={handleOpenOntegoDesignerDialog(overview.videoSrc)}
            >
                <img src={overview.image} width="100%" style={{ objectFit: 'contain' }} />
            </div>
            <div className={classes.designerOverviewIconContainer}>
                {overview.icon}
            </div>
            <Typography
                fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                size={35}
                color={Color.neutral[0]}
                style={{ marginTop: 25 }}
            >
                {overview.title}
            </Typography>
            <Typography
                fontFamily={FONT_FAMILY.ROBOTO_LIGHT}
                size={24}
                color={Color.neutral[0]}
                style={{ marginTop: -10, marginBottom: 32 }}
            >
                {overview.subtitle}
            </Typography>
            <Typography
                fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                size={14}
                color={Color.neutral[0]}
            >
                {overview.info}
            </Typography>
        </div>
    )
}

const WarehouseLogistics = props => {
    const classes = useStyles(props)
    const { collection, handleChangeCollection } = useContext(AppContext)

    const { handleGoToFunctionConfiguration } = props

    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={0} style={{ placeContent: 'flex-start' }}>
                <Grid item md={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 72, height: 72, marginTop: -124, marginLeft: -13 }}>
                        <div className={classes.smallerScenarioListDiamondBackground}>
                            <div className={classes.smallerScenarioListIconContainer}>
                                <Warehouse />
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // width: 341,
                            height: 320,
                            background: Color.neutral[300],
                            backgroundImage: `url(${WarehouseLogisticsImg})`,
                            backgroundSize: 'cover',
                            borderRadius: 12,
                        }}
                    >
                    </div>
                </Grid>
                <Grid item md={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={24}
                        color={ColorSAP.neutral[100]}
                        style={{ lineHeight: '34px', marginTop: 20 }}
                    >
                        {t("scenario.warehouseLogistic")}
                    </Typography>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ height: 120, marginTop: 24, marginBottom: 48, lineHeight: '20px' }}
                    >
                        {t("scenario.warehouseLogisticExp")}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {WAREHOUSE_LOGISTICS_FUNCTIONS.map((func, index) => (
                    <Grid key={`func${index}`} item xs={6} sm={6} md={6}>
                        <ProcessCard
                            textFunctionDetail={`Function Detail`}
                            textAddedToCollection={t('functionDetails.removeFromCollection')}
                            textAddToCollection={t('functionDetails.addToCollection')}
                            func={{
                                textName: t(`scenario.${func.name}`),
                                type: func.type
                            }}
                            icon={getFunctionIconFromId(func.systemID)}
                            onClick={handleGoToFunctionConfiguration(func)}
                            onAddToCollectionOnClick={handleChangeCollection(func)}
                            isAdded={collection.filter(c => c.name === func.name).length === 1}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

const ProductionAndWarehouseEnchancePackage = props => {
    const classes = useStyles(props)
    const { collection, handleChangeCollection } = useContext(AppContext)

    const { handleGoToFunctionConfiguration } = props

    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={0} style={{ placeContent: 'flex-start' }}>
                <Grid item md={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 72, height: 72, marginTop: -124, marginLeft: -13 }}>
                        <div className={classes.smallerScenarioListDiamondBackground}>
                            <div className={classes.smallerScenarioListIconContainer}>
                                <RobotArm />
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // width: 341,
                            height: 320,
                            background: Color.neutral[300],
                            backgroundImage: `url(${MobileProductionSolutionsImg})`,
                            backgroundSize: 'cover',
                            borderRadius: 12,
                        }}
                    >
                    </div>
                </Grid>
                <Grid item md={12}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={24}
                        color={ColorSAP.neutral[100]}
                        style={{ lineHeight: '34px', marginTop: 20 }}
                    >
                        {t("scenario.prodWarehouseEnchancePackage")}
                    </Typography>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ height: 120, marginTop: 24, marginBottom: 48, lineHeight: '20px' }}
                    >
                        {t("scenario.prodWarehouseEnchancePackageExp")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {PRODUCTION_AND_WAREHOUSE_ENHANCE_PACKAGE_FUNCTIONS.map((func, index) => (
                    <Grid key={`func${index}`} item xs={12} sm={12} md={12}>
                        <ProcessCard
                            textFunctionDetail={`Function Detail`}
                            textAddedToCollection={`Added to Collection`}
                            textAddToCollection={`Add to Collection`}
                            func={{
                                textName: t(`scenario.${func.name}`),
                                type: func.type
                            }}
                            icon={getFunctionIconFromId(func.systemID)}
                            onClick={handleGoToFunctionConfiguration(func)}
                            onAddToCollectionOnClick={handleChangeCollection(func)}
                            isAdded={collection.filter(c => c.name === func.name).length === 1}
                        />
                    </Grid>
                ))}
                <Grid item xs={12} sm={12} md={12}>
                    <div style={{ height: 110 }} />
                </Grid>
            </Grid>
        </>
    )
}

const MobileInventorySolutions = props => {
    const classes = useStyles(props)
    const { collection, handleChangeCollection } = useContext(AppContext)

    const { handleGoToFunctionConfiguration } = props

    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={0} style={{ placeContent: 'flex-start' }}>
                <Grid item md={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 72, height: 72, marginTop: -124, marginLeft: -13 }}>
                        <div className={classes.smallerScenarioListDiamondBackground}>
                            <div className={classes.smallerScenarioListIconContainer}>
                                <Packages />
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item md={12}>
                    <div
                        style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 62 }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 745,
                                height: 320,
                                background: Color.neutral[300],
                                backgroundImage: `url(${MobileInventorySolutionsImg})`,
                                backgroundSize: 'cover',
                                borderRadius: 12,
                            }}
                        >
                        </div>

                        <div
                            style={{
                                width: 341,
                                marginLeft: 65
                            }}
                        >
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                size={24}
                                color={ColorSAP.neutral[100]}
                                style={{ lineHeight: '34px'}}
                            >
                                {t("scenario.mobileInventorySolutions")}
                            </Typography>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={14}
                                color={ColorSAP.neutral[100]}
                                style={{ marginTop: 24, lineHeight: '20px' }}
                            >
                                {t("scenario.mobileInventorySolutionsExp")}
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {MOBILE_INVENTORY_SOLUTIONS_FUNCTIONS.map((func, index) => (
                    <Grid key={`func${index}`} item xs={4} sm={4} md={4}>
                        <ProcessCard
                            textFunctionDetail={`Function Detail`}
                            textAddedToCollection={t('functionDetails.removeFromCollection')}
                            textAddToCollection={t('functionDetails.addToCollection')}
                            func={{
                                textName: t(`scenario.${func.name}`),
                                type: func.type
                            }}
                            icon={getFunctionIconFromId(func.systemID)}
                            onClick={handleGoToFunctionConfiguration(func)}
                            onAddToCollectionOnClick={handleChangeCollection(func)}
                            isAdded={collection.filter(c => c.name === func.name).length === 1}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

const SpecialFunctions = props => {
    const classes = useStyles(props)

    const { handleGoToFunctionConfiguration } = props

    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={0} style={{ alignItems: "center" }}>
                <Grid item md={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 72, height: 72, marginTop: -124, marginLeft: -13 }}>
                        <div className={classes.smallerScenarioListDiamondBackground}>
                            <div className={classes.smallerScenarioListIconContainer}>
                                <Puzzle />
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item md={8}>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                        size={24}
                        color={ColorSAP.neutral[100]}
                        style={{ letterSpacing: -0.8, marginTop: -30 }}
                    >
                        {t("scenario.specialFunctionExt")}
                    </Typography>
                    <Typography
                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                        size={14}
                        color={ColorSAP.neutral[100]}
                        style={{ width: 746, height: 120, marginTop: 37, lineHeight: '22px' }}
                    >
                        {t("scenario.specialFunctionExtExp")}
                    </Typography>
                    <Button
                        size="medium"
                        theme="dark"
                        onClick={handleGoToFunctionConfiguration(FUNCTION_SONDERFUNKTIONEN_ERWEITERUNGEN)}
                    >
                        {t("scenario.functionDetail")}
                    </Button>
                </Grid>
                <Grid item md={4} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 25 }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 350,
                            height: 270,
                            background: Color.neutral[300],
                            marginTop: -60,
                            backgroundImage: `url(${SonderfunktionenImage})`,
                            backgroundSize: 'cover',
                            borderRadius: 12,
                        }}
                    >
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

const OntegoDesigner = props => {
    const classes = useStyles(props)

    const { handleOpenOntegoDesignerDialog } = props

    const { t } = useTranslation();


    const DesignerOverviewLists = [
        {
            icon: <View />,
            title: t("scenario.userInterfaceScenario"),
            subtitle: t("scenario.userInterfaceScenarioSub"),
            info: t("scenario.userInterfaceScenarioInfo"),
            image: UserInterfaceDesignerImage,
            videoSrc: `https://www.youtube.com/embed/r_BkTi6Zr54`
        },
        {
            icon: <Code />,
            title: t("scenario.backendScenario"),
            subtitle: t("scenario.userInterfaceScenarioSub"),
            info: t("scenario.backendScenarioInfo"),
            image: BackendDesignerImage,
            videoSrc: `https://www.youtube.com/embed/AYTFdtyaoj4`
        },
        {
            icon: <ScenarioOverview />,
            title: t("scenario.applicationFlowScenario"),
            subtitle: t("scenario.userInterfaceScenarioSub"),
            info: t("scenario.applicationFlowInfo"),
            image: ApplicationFlowDesignerImage,
            videoSrc: `https://www.youtube.com/embed/cnXTyURMzgE`
        }
    ]

    return (
        <>
            <Grid id="scenario-ontego-designer" container spacing={2}>
                <Grid item md={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 65, height: 65, marginTop: -57, marginLeft: -12 }}>
                        <div className={classes.smallerScenarioListDiamondBackground}>
                            <div className={classes.smallerScenarioListIconContainer}>
                                <Architect style={{ marginLeft: 8 }} />
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item md={8}>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={10}
                        color={Color.neutral[400]}
                        style={{ marginTop: 25 }}
                    >
                        {t("scenario.integratedDevelopmentEnv")}
                    </Typography>
                    <Typography
                        fontFamily={FONT_FAMILY.CAPITOLIUM_BOLD}
                        size={30}
                        color={Color.neutral[800]}
                        style={{ letterSpacing: -0.8, marginTop: 6 }}
                    >
                        {`Ontego Designer`}
                    </Typography>
                    <Typography
                        fontFamily={FONT_FAMILY.ROBOTO_REGULAR}
                        size={12}
                        color={Color.neutral[700]}
                        style={{ width: '85%', height: 120, marginTop: 20 }}
                    >
                        {t("scenario.integratedDevelopmentEnvExp")}
                    </Typography>
                    {/* <Button
                        alignIcon="right"
                        style={{ marginTop: 30, marginBottom: 75 }}
                    >
                        {`Play All`}
                    </Button> */}
                </Grid>
                <Grid item md={4}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 275,
                            background: Color.neutral[300],
                            marginTop: -75,
                            backgroundImage: `url(${OntegoDesignerImage})`,
                            backgroundSize: 'cover',
                            cursor: 'pointer'
                        }}
                        onClick={handleOpenOntegoDesignerDialog(`https://www.youtube.com/embed/2-noAK1NOKY`)}
                    />
                </Grid>
            </Grid>
            <div style={{ height: 80 }} />
            <Grid container spacing={2}>
                {DesignerOverviewLists.map((overview, index) => (
                    <Grid key={`overview${index}`} item md={4}>
                        <DesignerOverview
                            overview={overview}
                            handleOpenOntegoDesignerDialog={handleOpenOntegoDesignerDialog}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

const ScenarioList = props => {
    const classes = useStyles(props)

    const { children, style } = props

    return (
        <div
            className={classes.scenarioListContainer}
            style={{ ...style }}
        >
            {children}
        </div>
    )
}

const Scenario = props => {
    const classes = useStyles(props)
    const history = useHistory()
    const { t } = useTranslation()

    const { client, handleChangeInspectedFunctionId } = useContext(AppContext)

    // const [scrollYPos, setScrollYPos] = useState(0);
    const [scrollYPos, setScrollYPos] = usePersistedState('scrollYPos', 0);
    const [ontegoDesignerDialogOpen, setOntegoDesignerDialogOpen] = useState(false)

    const [activeSrc, setActiveSrc] = useState(null)

    const handleOpenOntegoDesignerDialog = src => () => {
        setActiveSrc(src)
        setOntegoDesignerDialogOpen(true)
    }
    const handleCloseOntegoDesignerDialog = () => setOntegoDesignerDialogOpen(false)



    useEffect(() => {
        window.scrollTo(0, scrollYPos)
    }, [])

    useEffect(() => {
        const onScroll = e => {
            setScrollYPos(window.scrollY)
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const BREADCRUMB_STACK = [
        {
            icon: <ScenarioOverview />,
            label: "Szenario Überblick",
            typeOf: "",
            onClick: () => { }
        }
    ]

    const handleGoToFunctionConfiguration = val => () => {
        handleChangeInspectedFunctionId(val.systemID)
        if (val.systemID === F_SONDERFUNKTIONEN_ERWEITERUNGEN) {
            history.push(SPECIAL_FUNCTIONS_PATH)
        } else {
            history.push(FUNCTION_DETAILS_PATH)
        }
    }

    return (
        <RootWrapperContainer>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12}>
                    <PaddedContainer>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 48, marginTop: 16 }}>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                size={28}
                                color={ColorSAP.neutral[100]}
                            >
                                {t("scenario.greetingWords")} {client.name}
                            </Typography>
                        </div>
                        {/* <div id='breadcrumb-container' style={{ width: '100%', position: 'fixed', top: 126, paddingTop: 24, paddingBottom: 24, background: Color.neutral[0], zIndex: 1200 }}>
                        <PaddedContainer>
                            <Breadcrumb stacks={BREADCRUMB_STACK} />
                        </PaddedContainer>
                    </div> */}
                    </PaddedContainer>
                </Grid>

                <PaddedContainer>
                    <Grid container spacing={0}>
                        <Grid item md={12}>
                            <div style={{ marginBottom: 110 }}>
                                <OntegoChecklists />
                            </div>
                        </Grid>
                    </Grid>
                </PaddedContainer>

                <PaddedContainer>
                    <Grid container spacing={2}>
                        <Grid item md={8}>
                            <ScenarioList>
                                <WarehouseLogistics handleGoToFunctionConfiguration={handleGoToFunctionConfiguration} />
                            </ScenarioList>
                        </Grid>

                        <Grid item md={4}>
                            <ScenarioList
                                style={{
                                    height: '100%'
                                }}
                            >
                                <ProductionAndWarehouseEnchancePackage handleGoToFunctionConfiguration={handleGoToFunctionConfiguration} />
                            </ScenarioList>
                        </Grid>

                        <Grid item md={12}>
                            <div style={{ height: 90 }} />
                        </Grid>

                        <Grid item md={12}>
                            <ScenarioList>
                                <MobileInventorySolutions handleGoToFunctionConfiguration={handleGoToFunctionConfiguration} />
                            </ScenarioList>
                        </Grid>

                        <Grid item md={12}>
                            <div style={{ height: 90 }} />
                        </Grid>

                        <Grid item md={12}>
                            <ScenarioList>
                                <SpecialFunctions handleGoToFunctionConfiguration={handleGoToFunctionConfiguration} />
                            </ScenarioList>
                        </Grid>

                        <Grid item md={12}>
                            <div style={{ height: 212 }} />
                        </Grid>
                    </Grid>
                </PaddedContainer>

                {/* <PaddedContainer>
                    <Grid container spacing={2}>

                    </Grid>
                </PaddedContainer> */}

                {/* <Grid item xs={12} sm={12} md={12}>
                    <PaddedContainer>
                        <ScenarioList
                            style={{ marginBottom: '10vh' }}
                        >
                            <OntegoDesigner handleOpenOntegoDesignerDialog={handleOpenOntegoDesignerDialog} />
                        </ScenarioList>
                    </PaddedContainer>
                </Grid> */}
            </Grid>
            {ontegoDesignerDialogOpen && (
                <OntegoDesignerDialog
                    open={ontegoDesignerDialogOpen}
                    onClose={handleCloseOntegoDesignerDialog}
                    src={activeSrc}
                />
            )}
        </RootWrapperContainer>
    )
}

export default Scenario